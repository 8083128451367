import React, { useEffect, useRef, useState } from 'react';
import { AccordianTitle } from './AccordianTitle';
import { AccordianBody } from './AccordianBody';
import { Mode } from '../constants/Mode';
import styles from './Expand.module.scss';
import { AccordianTextFragment } from '../types/schema';

interface IExpand {
  duration?: number | undefined;
  mode?: Mode | string;
  title: string;
  body: AccordianTextFragment[];
}

const Expand = ({ title, body, mode = Mode.LIGHT }: IExpand) => {
  const [height, setHeight] = useState(0);
  const ref = useRef(null as null | HTMLDivElement);
  const [active, setActive] = useState(false);

  useEffect(() => {
    if (ref !== null && ref.current !== null) {
      setHeight(ref.current.scrollHeight); // 16 stands for the padding top and bottom
    }
    const handleResize = () => {
      if (ref !== null && ref.current !== null) setHeight(ref.current.scrollHeight);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [setHeight]);

  return (
    <div className={styles.root}>
      <AccordianTitle icon="s" active={active} handleClick={setActive} mode={mode}>
        {title}
      </AccordianTitle>
      <div
        className={styles.expander}
        style={{
          height: active ? height : 0,
          transition: 'height .35s ease',
        }}
      >
        <div
          ref={ref}
          className={styles.text}
          style={{
            height: active ? height : 0,
            transition: 'height .35s ease',
            overflow: 'hidden',
          }}
        >
          <AccordianBody active={active} mode={mode} body={body} />
        </div>
      </div>
    </div>
  );
};

export default Expand;
