import React from 'react';
import styles from './FaqSelector.module.scss';
import { Faq, IFaq } from './Faq';

export interface IFaqSelector {
  faqs: IFaq[]
}

export const FaqSelector = ({ faqs }: IFaqSelector) => (
  <div className={styles.root}>
    {faqs.map((item, key) => (
      <Faq key={key} {...item} />
    ))}
  </div>
);
