/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-unused-vars */
export enum CURRENT_LENDER {
  AMP = 'AMP Bank Ltd',
  ANZ = 'ANZ',
  BANK_OF_MELBOURNE = 'Bank of Melbourne',
  BANK_OF_QUEENSLAND = 'Bank of Queensland',
  BANKWEST = 'Bankwest',
  BENDIGO_AND_ADELAIDE_BANK = 'Bendigo and Adelaide Bank',
  CITIBANK = 'Citibank',
  COMMONWEALTH_BANK = 'Commonwealth Bank',
  CUA = 'CUA',
  HSBC = 'HSBC',
  ING_DIRECT = 'ING Direct',
  MACQUARIE_BANK = 'Macquarie Bank',
  NAB = 'National Australia Bank (NAB)',
  ST_GEORGE_BANK = 'St George Bank',
  SUNCORP_BANK = 'Suncorp Bank',
  WESTPAC_BANK = 'Westpac Bank',
}
