/* eslint-disable @typescript-eslint/no-use-before-define */
export interface IRate {
  rate: number;
  comparisonRate: number;
  fixedRate: number;
  minLvr: number;
  maxLvr: number;
}

export interface IStandardRate {
  rate: number;
}

type LvrTierDetail = {
  '0-50': IRate;
  '0-60': IRate;
  '50-60': IRate;
  '60-70': IRate;
  '70-80': IRate;
};

interface IRepaymentInfoDetail {
  [propertyPurpose.OWNER_OCCUPIED]: LvrTierDetail;
  [propertyPurpose.INVESTMENT]: LvrTierDetail;
}

interface IRepaymentInfo {
  [repaymentType.INTEREST_ONLY]: IRepaymentInfoDetail;
  [repaymentType.PRINCIPAL_AND_INTEREST]: IRepaymentInfoDetail;
}

interface IStandardRepaymentInfo {
  [key: repaymentType[0] | repaymentType[1]]: {
    [key: propertyPurpose[0] | propertyPurpose[1]]: IStandardRate;
  };
}

export interface IFixedRepaymentInfo {
  [FixedYears.ONE]: IRepaymentInfo;
  [FixedYears.TWO]: IRepaymentInfo;
  [FixedYears.THREE]: IRepaymentInfo;
}

interface IFixedStandardRepaymentInfo {
  [key: FixedYears[0] | FixedYears[1] | FixedYears[2]]: {
    [key: repaymentType[0] | repaymentType[1]]: {
      [key: propertyPurpose[0] | propertyPurpose[1]]: IStandardRate;
    };
  };
}

export interface IRateResult {
  ['FIXED_RATE']: IFixedRepaymentInfo;
  ['VARIABLE_RATE']: IRepaymentInfo;
}

export interface IStandardRateResult {
  ['FIXED_RATE']: IFixedStandardRepaymentInfo;
  ['VARIABLE_RATE']: IStandardRepaymentInfo;
}

export interface IBookRate {
  [repaymentType.PRINCIPAL_AND_INTEREST]: {
    [propertyPurpose.OWNER_OCCUPIED]: IStandardRate;
    [propertyPurpose.INVESTMENT]: IStandardRate;
  };
  [repaymentType.INTEREST_ONLY]: {
    [propertyPurpose.OWNER_OCCUPIED]: IStandardRate;
    [propertyPurpose.INVESTMENT]: IStandardRate;
  };
  effectiveDate: string;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export enum nonStandardKeys {
  DIRECT = 'direct',
  DIRECT_BASICS = 'direct-basics',
  DIRECT_EXTRAS = 'direct-extras',
  SELECT = 'select',
  BACK_BOOK = 'back-book',
  FRONT_BOOK = 'front-book',
}
// eslint-disable-next-line @typescript-eslint/naming-convention
export enum standardKeys {
  STANDARD = 'standard',
  STANDARD_BASICS = 'standard-basics',
  STANDARD_EXTRAS = 'standard-extras',
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export enum repaymentType {
  PRINCIPAL_AND_INTEREST = 'PRINCIPAL_AND_INTEREST',
  INTEREST_ONLY = 'INTEREST_ONLY',
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export enum propertyPurpose {
  INVESTMENT = 'INVESTMENT',
  OWNER_OCCUPIED = 'OWNER_OCCUPIED',
}

export type LVRTier = '0-50' | '0-60' | '50-60' | '60-70' | '70-80';

export enum FixedYears {
  ONE = '1',
  TWO = '2',
  THREE = '3',
}
/* eslint-enable */

type RateResult = [
  IRateResult,
  IRateResult,
  IRateResult,
  IStandardRateResult,
  IStandardRateResult,
  IStandardRateResult,
  IRateResult,
  IBookRate,
  IBookRate,
];

// Make the `request` function generic
// to specify the return data type:
function request<TResponse>(
  url: string,
  // `RequestInit` is a type for configuring
  // a `fetch` request. By default, an empty object.

  // This function is async, it will return a Promise:
): Promise<TResponse> {
  // Inside, we call the `fetch` function with
  // a URL and config given:
  return (
    fetch(url)
      // When got a response call a `json` method on it
      .then((response) => response.json())
      // and return the result data.
      .then((data) => data as TResponse)
  );

  // We also can use some post-response
  // data-transformations in the last `then` clause.
}

export interface IDictionaryRate {
  [nonStandardKeys.DIRECT]: IRateResult;
  [nonStandardKeys.DIRECT_BASICS]: IRateResult;
  [nonStandardKeys.DIRECT_EXTRAS]: IRateResult;
  [standardKeys.STANDARD]: IStandardRateResult;
  [standardKeys.STANDARD_BASICS]: IStandardRateResult;
  [standardKeys.STANDARD_EXTRAS]: IStandardRateResult;
  [nonStandardKeys.SELECT]: IRateResult;
  [nonStandardKeys.BACK_BOOK]: IBookRate;
  [nonStandardKeys.FRONT_BOOK]: IBookRate;
}

const fetchRatesAsync = async (): Promise<RateResult> => Promise.all([
  request<IRateResult>(`${process.env.RATES_SERVICE_URL}/direct`),
  request<IRateResult>(`${process.env.RATES_SERVICE_URL}/direct-basics`),
  request<IRateResult>(`${process.env.RATES_SERVICE_URL}/direct-extras`),
  request<IStandardRateResult>(`${process.env.RATES_SERVICE_URL}/standard`),
  request<IStandardRateResult>(`${process.env.RATES_SERVICE_URL}/standard-basics`),
  request<IStandardRateResult>(`${process.env.RATES_SERVICE_URL}/standard-extras`),
  request<IRateResult>(`${process.env.RATES_SERVICE_URL}/select`),
  // Market is backbook
  request<IBookRate>(`${process.env.RATES_SERVICE_URL}/market`),
  request<IBookRate>(`${process.env.RATES_SERVICE_URL}/front-book`),
]).then((data: RateResult) => data);

export const fetchRates = async (): Promise<IDictionaryRate> => {
  const rateResult = await fetchRatesAsync();

  const rates: IDictionaryRate = {
    direct: rateResult[0],
    'direct-basics': rateResult[1],
    'direct-extras': rateResult[2],
    standard: rateResult[3],
    'standard-basics': rateResult[4],
    'standard-extras': rateResult[5],
    select: rateResult[6],
    'back-book': rateResult[7],
    'front-book': rateResult[8],
  };

  return rates;
};
