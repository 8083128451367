import React from 'react';

import { JustifyContents } from 'constants/JustifyContents';
import { ContainerSizes } from 'constants/ContainerSizes';
import { ColumnSizes } from '../constants/ColumnSizes';
import { WrapOrders } from '../constants/WrapOrders';
import { MediaModes } from '../constants/MediaModes';
import { TextModes } from '../constants/TextModes';

import { Container } from './Container';
import { Column } from './Column';
import { Row } from './Row';
import { TextBlock, ITextBlock } from './TextBlock';
import { ImageBlock, IImageBlock } from './ImageBlock';
import { TabBlock, ITabBlock } from './TabBlock';
import { ContentBlock, IContentBlock } from './ContentBlock';
import { CardTilesBlock, ICardTilesBlock } from './CardTilesBlock';
import { Elevations } from '../constants/Elevations';

import styles from './MediaBlock.module.scss';
import { IForm, LeadsForm } from './leadsForms/LeadsForm';

export interface IMediaBlock {
  text?: ITextBlock;
  image?: IImageBlock;
  tabs?: ITabBlock;
  content?: IContentBlock;
  cards?: ICardTilesBlock;
  form?: IForm;
  mediaMode?: MediaModes | string;
  textMode?: TextModes | string;
  desktopMediaOrder?: WrapOrders | string;
  mobileMediaOrder?: WrapOrders | string;
  desktopTextWidth?: ColumnSizes | string;
  desktopMediaWidth?: ColumnSizes | string;
  mobileTextWidth?: ColumnSizes | string;
  mobileMediaWidth?: ColumnSizes | string;
  desktopJustify?: JustifyContents | string;
  mobileJustify?: JustifyContents | string;
  contentBg?: string;
  elevation?: Elevations | string;
  id?: string
}

export const MediaBlock = ({
  cards,
  text,
  image,
  tabs,
  content,
  form,
  desktopMediaOrder = WrapOrders.ROW,
  mediaMode = MediaModes.IMAGE,
  mobileMediaOrder = WrapOrders.ROW,
  textMode = TextModes.TEXT_AND_MEDIA,
  desktopTextWidth = ColumnSizes.ONE_THIRD,
  desktopMediaWidth = ColumnSizes.TWO_THIRDS,
  mobileTextWidth = ColumnSizes.FULL,
  mobileMediaWidth = ColumnSizes.FULL,
  desktopJustify = JustifyContents.START,
  mobileJustify = JustifyContents.START,
  contentBg = 'initial',
  elevation = Elevations.FULL,
  id,
}: IMediaBlock) => {
  // eslint-disable-next-line object-shorthand
  const elementProps = id ? { id: id } : {};
  return (
    <div {...elementProps}>
      <Row xxsWrap={mobileMediaOrder} lgWrap={desktopMediaOrder} xxsJust={mobileJustify} lgJust={desktopJustify}>
        {(textMode === TextModes.TEXT_AND_MEDIA || textMode === TextModes.MEDIA_NOT_VISIBLE) && (
        <Column xxs={mobileTextWidth} lg={desktopTextWidth}>
          <div className={styles.text}>
            <TextBlock {...text} />
          </div>
        </Column>
        )}
        {(textMode === TextModes.TEXT_AND_MEDIA || textMode === TextModes.TEXT_NOT_VISIBLE) && (
        <Column xxs={mobileMediaWidth} lg={desktopMediaWidth}>
          {mediaMode === MediaModes.IMAGE && <ImageBlock {...image} />}
          {mediaMode === MediaModes.TWO_COLUMN_CONTENT_BLOCK && (
          <div className={styles.content} style={{ background: `${contentBg}` }}>
            <Container size={ContainerSizes.FULL}>
              <Row
                xxsWrap={mobileMediaOrder}
                lgWrap={desktopMediaOrder}
                xxsJust={mobileJustify}
                lgJust={desktopJustify}
              >
                <ContentBlock {...content} />
              </Row>
            </Container>
          </div>
          )}
          {mediaMode === MediaModes.CARD_TILES && (
          <div className={styles.content}>
            <CardTilesBlock {...cards} />
          </div>
          )}
          {mediaMode === MediaModes.TAB && (
          <div className={styles.content}>
            <Container size={ContainerSizes.FULL}>
              <Row
                xxsWrap={mobileMediaOrder}
                lgWrap={desktopMediaOrder}
                xxsJust={mobileJustify}
                lgJust={desktopJustify}
              >
                <TabBlock {...tabs} contentBg={contentBg} elevation={elevation} />
              </Row>
            </Container>
          </div>
          )}
          {mediaMode === MediaModes.FORM && form && <LeadsForm {...form} />}
        </Column>
        )}
      </Row>
    </div>
  );
};
