import { Cohorts } from '@/constants/cohorts';
import { CohortPageRoutes } from '@/constants/PageRoutes';

const { INVESTORS, HOME_OWNERS } = Cohorts;

type CTAProps = {
  label: string;
  href: string;
};

type SwitchCohortProps = CTAProps & {
  cohort: Cohorts;
};

export interface CohortContent {
  backgroundColor: string;
  title: string;
  underlinedContent: string;
  description: React.ReactNode;
  videoUrl: string;
  ctas: CTAProps[];
  switchCohort: SwitchCohortProps;
}

export const homeOwnerContent = {
  backgroundColor: 'rgba(33, 2, 52, 1)',
  title: 'Bring it',
  underlinedContent: 'home',
  description: 'Own your home sooner, lose your loan faster',
  videoUrl:
    'https://videos.ctfassets.net/xksnh4j607ep/5h2LE78Liys3jNggedD0B9/dd179e3101b6b177089d835dcd91dca8/SS-Ev-OOC-12s.mp4',
  ctas: [
    {
      label: "I'm refinancing",
      href: CohortPageRoutes.HOME_OWNERS.REFINANCING,
    },
    {
      label: "I'm buying",
      href: CohortPageRoutes.HOME_OWNERS.BUYING,
    },
  ],
  switchCohort: {
    label: 'See options for Investors',
    href: '/investors',
    cohort: INVESTORS,
  },
};

export const investorContent = {
  backgroundColor: 'rgba(75, 6, 82, 1)',
  title: 'The home of',
  underlinedContent: 'investors',
  description: <p>Athena gives you the edge to power your portfolio</p>,
  videoUrl:
    'https://videos.ctfassets.net/xksnh4j607ep/5eWVVYqUcXv3gVl7LAaNbU/3f5666bccc7ea074bbc97cd0f60b17cf/SS-Ev-INV-9s.mp4',
  ctas: [
    {
      label: "I'm refinancing",
      href: CohortPageRoutes.INVESTORS.REFINANCING,
    },
    {
      label: "I'm buying",
      href: CohortPageRoutes.INVESTORS.BUYING,
    },
  ],
  switchCohort: {
    label: 'See options for Home Owners',
    href: '/',
    cohort: HOME_OWNERS,
  },
};
