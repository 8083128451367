import React from 'react';
import styles from './TabBar.module.scss';
import { StringTab } from './StringTab';

import { TabTypes } from '../constants/TabTypes';
import { Mode } from '../constants/Mode';

export interface ITabBar {
  setActive: (index: 'Straight Up' | 'Power Up' | 'Fixed') => void;
  active: string | null;
  mode: Mode | string;
  tabs: ('Straight Up' | 'Power Up' | 'Fixed')[];
  type: TabTypes;
  stretch?: boolean;
}

export const TabBarString = ({
  active, mode, setActive, tabs, type, stretch = false,
}: ITabBar) => (
  <div className={`${styles.root} ${styles[type]} ${stretch === true ? styles.stretch : styles['no-stretch']}`}>
    {tabs.map((tab, i) => (
      <StringTab
        active={active === tab}
        key={i}
        tab={tab}
        mode={mode}
        clickHander={setActive}
        type={type}
        stretch={stretch}
      >
        {tab}
      </StringTab>
    ))}
  </div>
);
