/* eslint-disable no-param-reassign */
import { FormInputNames } from '@/constants/FormInputNames';
import { MutableRefObject } from 'react';
import { setData } from 'utilities/leadsObjAssign';
import { IFormTextInputs } from '../components/leadsForms/FormTextInputs';
import { LeadsRequest } from '../types/leadsRequest';
import { assignEnumValue } from './leadsEnumMappers';

export interface ILeadsMapper {
  textInputs?: IFormTextInputs[];
  fieldRefs: MutableRefObject<{
    [key: string]: HTMLInputElement | HTMLTextAreaElement | null;
  }>;
}

const assignStringValue = (
  fieldRefs: MutableRefObject<{
    [key: string]: HTMLInputElement | HTMLTextAreaElement | null;
  }>,
  name: string,
  dataField: keyof LeadsRequest,
  data: LeadsRequest,
) => {
  if (fieldRefs?.current[name] && fieldRefs.current[name]?.value) {
    setData(dataField, fieldRefs.current[name]?.value, data);
  }
};

const assignNumValue = (
  fieldRefs: MutableRefObject<{
    [key: string]: HTMLInputElement | HTMLTextAreaElement | null;
  }>,
  name: string,
  dataField: keyof LeadsRequest,
  data: LeadsRequest,
) => {
  if (fieldRefs?.current[name] && fieldRefs.current[name]?.value) {
    setData(dataField, Number(fieldRefs.current[name]?.value as string), data);
  }
};

export const leadsMapperService = ({ fieldRefs }: ILeadsMapper): LeadsRequest => {
  const data: LeadsRequest = {
    firstName: fieldRefs.current[FormInputNames.FIRST_NAME]?.value
      ? fieldRefs.current[FormInputNames.FIRST_NAME]?.value
      : '',
  };
  assignNumValue(fieldRefs, FormInputNames.ATHENA_LOAN_TERM, FormInputNames.ATHENA_LOAN_TERM, data);
  assignStringValue(fieldRefs, FormInputNames.CURRENT_LENDER_OTHER, FormInputNames.CURRENT_LENDER_OTHER, data);
  assignNumValue(fieldRefs, FormInputNames.CURRENT_LOAN_AMOUNT, FormInputNames.CURRENT_LOAN_AMOUNT, data);
  assignNumValue(fieldRefs, FormInputNames.CURRENT_RATE, FormInputNames.CURRENT_RATE, data);
  assignStringValue(fieldRefs, FormInputNames.DESCRIPTION, FormInputNames.DESCRIPTION, data);
  assignStringValue(fieldRefs, FormInputNames.EMAIL, FormInputNames.EMAIL, data);
  assignStringValue(fieldRefs, FormInputNames.SURNAME, FormInputNames.SURNAME, data);
  assignStringValue(fieldRefs, FormInputNames.MOBILE_NUMBER, FormInputNames.MOBILE_NUMBER, data);
  assignStringValue(fieldRefs, FormInputNames.POST_CODE, FormInputNames.POST_CODE, data);
  assignNumValue(fieldRefs, FormInputNames.PROPERTY_VALUE, FormInputNames.PROPERTY_VALUE, data);
  assignNumValue(fieldRefs, FormInputNames.REPAYMENT_AMOUNT, FormInputNames.REPAYMENT_AMOUNT, data);
  assignEnumValue(fieldRefs, FormInputNames.ATHENA_LENDING_PRODUCT, data);
  assignEnumValue(fieldRefs, FormInputNames.PROPERTY_TYPE, data);
  assignEnumValue(fieldRefs, FormInputNames.REPAYMENT_FREQUENCY, data);
  assignEnumValue(fieldRefs, FormInputNames.CURRENT_LENDER, data);
  return data;
};
