import React, { useEffect, useState } from 'react';
import styles from './NewArticles.module.scss';

export interface INewArticles {
  children?: React.ReactNode;
  imgOne: string;
  imgTwo: string;
  imgThree: string;
  handleClickEnd: (index: number) => void;
}

export const NewArticles = ({
  children, imgOne = '', imgTwo = '', imgThree = '', handleClickEnd,
}: INewArticles) => {
  let x: number;

  const mouseMoveHandler = (e: MouseEvent | TouchEvent) => {
    const ele = document.querySelector<HTMLElement>('.active');
    // How far the mouse has been moved
    let dx;

    if (e instanceof MouseEvent && e.clientX) {
      dx = e.clientX - x;
    } else if (x && e instanceof TouchEvent && e.changedTouches && e.changedTouches[0] && e.changedTouches[0].clientX) {
      dx = Math.round(e.changedTouches[0].clientX - x);
    }

    // Set the position of element
    if (dx && ele) {
      ele.style.left = `${dx}px`;

      if (ele.offsetLeft > 0) {
        ele?.setAttribute('data-horizontal', 'right');
      }
      if (ele.offsetLeft < 0) {
        ele?.setAttribute('data-horizontal', 'left');
      }
    }
    // Reassign the position of mouse
  };

  const mouseUpHandler = () => {
    // Remove the handlers of `mousemove` and `mouseup`

    const ele = document.querySelector<HTMLElement>('.active');
    const rEle = document.querySelector('[data-radio="active"]');

    ele?.classList?.remove('active');
    rEle?.removeAttribute('data-radio');

    document.removeEventListener('mousemove', mouseMoveHandler);
    document.removeEventListener('touchmove', mouseMoveHandler);

    document.removeEventListener('mouseup', mouseUpHandler);
    document.removeEventListener('touchend', mouseUpHandler);

    if (ele) {
      if (ele.offsetLeft > 0) {
        ele?.setAttribute('data-horizontal', 'hide');
        ele.style.left = `${ele.offsetLeft + 1200}px`;
      }
      if (ele.offsetLeft < 0) {
        ele?.setAttribute('data-horizontal', 'hide');
        ele.style.left = `${ele.offsetLeft + -1200}px`;
      }
    }

    if (document.querySelector('[data-pos="middle"]')) {
      const mid = document.querySelector('[data-pos="middle"]');
      const radio = document.querySelector('[data-pos="bottom"]')
        ? document.querySelector('[data-rpos="mid"]')
        : document.querySelector('[data-rpos="bottom"]');
      const filler = document.querySelector('[data-filler="fmid"]');
      filler?.setAttribute('data-filler', '');
      radio?.setAttribute('data-radio', 'active');
      mid?.setAttribute('data-pos', 'top');
    }
    if (document.querySelector('[data-pos="bottom"]')) {
      const bot = document.querySelector('[data-pos="bottom"]');
      const filler = document.querySelector('[data-filler="fbottom"]');
      filler?.setAttribute('data-filler', 'fmid');
      bot?.setAttribute('data-pos', 'middle');
    }

    ele?.setAttribute('data-pos', '');

    // reset
    setTimeout(() => {
      if (document.querySelector('[data-pos="top"]') === null) {
        const slides = document.querySelectorAll<HTMLElement>('[data-name="slide"]');
        slides.forEach((slide) => {
          slide.removeAttribute('data-name');
          slide.removeAttribute('data-pos');
          slide.removeAttribute('data-horizontal');
          slide.setAttribute('data-name', 'slide');
          // eslint-disable-next-line no-param-reassign
          slide.style.left = `${0}px`;
          if (slide.getAttribute('data-slide') === '0') {
            slide.setAttribute('data-pos', 'bottom');
            const fill = slide.querySelector("[data-filler='']");
            fill?.setAttribute('data-filler', 'fbottom');
            const attr = document.querySelector('[data-rpos="top"]');
            attr?.setAttribute('data-radio', 'active');
          } else if (slide.getAttribute('data-slide') === '1') {
            slide.setAttribute('data-pos', 'middle');
            const fill = slide.querySelector("[data-filler='']");
            fill?.setAttribute('data-filler', 'fmid');
          } else if (slide.getAttribute('data-slide') === '2') {
            slide.setAttribute('data-pos', 'top');
          }
        });
      }
    }, 300);
  };

  const mouseDownHandler = (e: React.MouseEvent<Element, MouseEvent>) => {
    // Get the current mouse position
    x = e?.clientX;
    (e?.target as HTMLElement)?.classList?.add('active');
    // Attach the listeners to `document`
    document.addEventListener('mousemove', mouseMoveHandler, true);
    document.addEventListener('touchmove', mouseMoveHandler, true);

    document.addEventListener('mouseup', mouseUpHandler);
    document.addEventListener('touchend', mouseUpHandler);
  };

  const touchStartHandler = (e: React.TouchEvent<Element>) => {
    x = e?.touches[0]?.clientX;
    (e?.target as HTMLElement)?.classList?.add('active');
    // Attach the listeners to `document`
    document.addEventListener('mousemove', mouseMoveHandler, true);
    document.addEventListener('touchmove', mouseMoveHandler, true);

    document.addEventListener('mouseup', mouseUpHandler);
    document.addEventListener('touchend', mouseUpHandler);
  };

  const [imgOneSmall, setImgOneSmall] = useState<string>('');
  const [imgOneMed, setImgOneMed] = useState<string>('');
  const [imgSecondSmall, setImgSecondSmall] = useState<string>('');
  const [imgSecondMed, setImgSecondMed] = useState<string>('');
  const [imgThirdSmall, setImgThirdSmall] = useState<string>('');
  const [imgThirdMed, setImgThirdMed] = useState<string>('');

  useEffect(() => {
    const imgs = [
      {
        small: `${imgOne}?fm=webp&w=284&h=284`,
        med: `${imgOne}?fm=webp&w=460&h=460`,
      },
      {
        small: `${imgTwo}?fm=webp&w=284&h=284`,
        med: `${imgTwo}?fm=webp&w=460&h=460`,
      },
      {
        small: `${imgThree}?fm=webp&w=284&h=284`,
        med: `${imgThree}?fm=webp&w=460&h=460`,
      },
    ];
    setImgOneSmall(imgs[0].small);
    setImgOneMed(imgs[0].med);
    setImgSecondSmall(imgs[1].small);
    setImgSecondMed(imgs[1].med);
    setImgThirdSmall(imgs[2].small);
    setImgThirdMed(imgs[2].med);
  }, []);

  return (
    <>
      <style media="all and (max-width: 991px)">
        {`
        html body .imageOne{
          background-image: url(${imgOneSmall});
        }
    `}
      </style>
      <style media="all and (min-width: 992px)">
        {`
        html body .imageOne{
          background-image: url(${imgOneMed});
        }
    `}
      </style>
      <style media="all and (-webkit-min-device-pixel-ratio: 2) and (min-resolution:192dpi) and (min-width: 992px)">
        {`
        html body .imageOne{
          background-image: url(${imgOne}?fm=webp);
        }
    `}
      </style>
      <style media="all and (max-width: 991px)">
        {`
        html body .imageTwo{
          background-image: url(${imgSecondSmall});
        }
    `}
      </style>
      <style media="all and (min-width: 992px)">
        {`
        html body .imageTwo{
          background-image: url(${imgSecondMed});
        }
    `}
      </style>
      <style media="all and (-webkit-min-device-pixel-ratio: 2) and (min-resolution:192dpi) and (min-width: 992px)">
        {`
        html body .imageTwo{
          background-image: url(${imgTwo}?fm=webp);
        }
    `}
      </style>
      <style media="all and (max-width: 991px)">
        {`
        html body .imageThree{
          background-image: url(${imgThirdSmall});
        }
    `}
      </style>
      <style media="all and (min-width: 992px)">
        {`
        html body .imageThree{
          background-image: url(${imgThirdMed});
        }
    `}
      </style>
      <style media="all and (-webkit-min-device-pixel-ratio: 2) and (min-resolution:192dpi) and (min-width: 992px)">
        {`
        html body .imageThree{
          background-image: url(${imgThree}?fm=webp);
        }
    `}
      </style>

      <div className={styles.root}>
        <div className={styles.block}>
          <div className={styles.wrapper}>
            <div
              data-pos="bottom"
              data-slide="0"
              onMouseDown={mouseDownHandler}
              onTouchStart={touchStartHandler}
              onMouseUp={() => handleClickEnd(0)}
              onTouchEnd={() => handleClickEnd(0)}
              data-name="slide"
            >
              <div
                className={`${styles.images} imageOne`}
                style={{
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'contain',
                  backgroundPosition: 'center',
                }}
              >
                <div data-filler="fbottom" />
              </div>
            </div>
            <div
              data-pos="middle"
              data-slide="1"
              onMouseDown={mouseDownHandler}
              onTouchStart={touchStartHandler}
              data-name="slide"
              onMouseUp={() => handleClickEnd(2)}
              onTouchEnd={() => handleClickEnd(2)}
            >
              <div
                className={`${styles.images} imageTwo`}
                style={{
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'contain',
                  backgroundPosition: 'center',
                }}
              >
                <div data-filler="fmid" />
              </div>
            </div>
            <div
              data-pos="top"
              data-slide="2"
              onMouseDown={mouseDownHandler}
              onTouchStart={touchStartHandler}
              data-name="slide"
              onMouseUp={() => handleClickEnd(1)}
              onTouchEnd={() => handleClickEnd(1)}
            >
              <div
                className={`${styles.images} imageThree`}
                style={{
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'contain',
                  backgroundPosition: 'center',
                }}
              />
            </div>
          </div>
        </div>
        {children}
        <div className={`${styles['radio-container']}`}>
          <label data-rpos="top" data-radio="active" className={styles.radio}>
            <span className={styles.outer}>
              <span />
            </span>
          </label>
          <label data-rpos="mid" className={styles.radio}>
            <span className={styles.outer}>
              <span className={styles.inner} />
            </span>
          </label>
          <label data-rpos="bottom" className={styles.radio}>
            <span className={styles.outer}>
              <span className={styles.inner} />
            </span>
          </label>
        </div>
      </div>
    </>
  );
};
