import React from 'react';
import NextLink from 'next/link';

/* SCSS */
import styles from './HomePageBanner.module.scss';

type CohortSelectButtonType = {
  active: boolean;
  label: string;
  href: string;
  onClick?: () => void;
};

export const CohortSelectButton = ({
  active, label, href, onClick,
}: CohortSelectButtonType) => (
  <NextLink href={href} className={`${styles.cohortButton} ${active ? styles.active : ''}`} onClick={onClick}>
    {label}
  </NextLink>
);
