import React from 'react';
import { WrapOrders } from '@/constants/WrapOrders';
import { JustifyContents } from '@/constants/JustifyContents';
import { CardVariantFragment } from '../types/schema';
import { CardVariant } from './CardVariant';
import { EyeBrow, IEyebrow } from './EyeBrow';
import { IText, Text } from './Text';
import { ITitle, Title } from './Title';
import { Row } from './Row';

export interface ICardVariantList {
  cardVariants: CardVariantFragment[];
  eyebrow?: IEyebrow;
  title?: ITitle;
  text?: IText;
}

export const CardVariantList = ({
  eyebrow, title, cardVariants, text,
}: ICardVariantList) => (
  <>
    <EyeBrow {...eyebrow} />
    <Title {...title} />
    <Text {...text} />
    <Row
      xxsWrap={WrapOrders.ROW}
      lgWrap={WrapOrders.ROW}
      xxsJust={JustifyContents.CENTER}
      lgJust={JustifyContents.CENTER}
    >
      {cardVariants.map((card, key) => <CardVariant key={key} card={card} anchorId={card.anchorId} />)}
    </Row>
  </>
);
