import { useEffect, useRef, useState } from 'react';

export const ScrollPercentage: React.FC<{
  children: Function;
  initial?: number;
  threshold?: number;
}> = ({
  children,
  initial = 0,
  threshold = 0.5, // Trigger when at least 50% of the component is in view
}) => {
  const [offset, setOffset] = useState<number>(initial);
  const [percentage, setPercentage] = useState<number>(initial);
  const observerRef = useRef<HTMLElement | null>(null);
  const handleScroll = () => {
    const numerator = observerRef.current ? observerRef.current.offsetTop - window.scrollY : 0;
    const denominator = window.innerHeight;
    setOffset(window.scrollY);
    setPercentage((numerator / denominator) * 100);
  };

  useEffect(() => {
    // Initialize the Intersection Observer
    const options: IntersectionObserverInit = {
      root: null,
      rootMargin: '0px',
      threshold,
    };

    if (observerRef.current) {
      const observer = new IntersectionObserver((entries) => {
        const [entry] = entries;
        if (entry.isIntersecting) {
          window.addEventListener('scroll', handleScroll);
        } else {
          window.removeEventListener('scroll', handleScroll);
        }
      }, options);

      observer.observe(observerRef.current);

      return () => {
        observer.disconnect();
        window.removeEventListener('scroll', handleScroll);
      };
    }
  }, [threshold]);

  // @ts-expect-error
  return <div ref={observerRef}>{children && children({ offset, percentage })}</div>;
};
