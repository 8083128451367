import React from 'react';
import { TextAlignments } from 'constants/TextAlignments';
import Link from 'next/link';
import { responsiveSrcGenerator } from 'utilities/responsiveSrcGenerator';
import { Elevations } from '../constants/Elevations';
import { ImageFits } from '../constants/ImageFits';
import styles from './ImageBlock.module.scss';
import { Button, IButton } from './Button';
import { srcSetGenerator } from '../utilities/srcSetGenerator';

export interface ITImage {
  url: string;
  title: string;
  width: number;
  height: number;
}
export interface IImageBlock {
  imageIsLink?: boolean;
  href?: string;
  linkTitle?: string;
  alignment?: TextAlignments | string;
  playIcon?: boolean;
  elevation?: Elevations | string;
  imageFit?: ImageFits | string;
  cta?: IButton;
  ctaVisible?: boolean;
  desktopImage?: ITImage;
  tabletImage?: ITImage;
  mobileImage?: ITImage;
}

export const ImageBlock = ({
  imageIsLink = false,
  href = '',
  linkTitle = '',
  alignment = TextAlignments.CENTER,
  elevation = Elevations.FULL,
  playIcon = false,
  imageFit = 'contain',
  desktopImage = {
    url: '/next-assets/media/placeholder.webp',
    width: 640,
    height: 480,
    title: 'placeholder',
  },
  tabletImage,
  mobileImage = {
    url: '/next-assets/media/placeholder.webp',
    width: 320,
    height: 240,
    title: 'placeholder',
  },
  ctaVisible,
  cta,
}: IImageBlock) => {
  if (!tabletImage || !tabletImage.url) {
    // eslint-disable-next-line no-param-reassign
    tabletImage = JSON.parse(JSON.stringify(mobileImage));
  }

  return (
    <>
      <div className={`${styles.root} ${styles[elevation]}  ${styles[alignment]} `}>
        <Link
          href={imageIsLink && href !== null && href !== undefined ? href : '#'}
          title={linkTitle}
          style={{ pointerEvents: imageIsLink ? 'all' : 'none' }}
        >
          {desktopImage && desktopImage.height
            && (
            <div className={`${styles.desktopImage} ${styles[imageFit]}`} style={{ maxHeight: desktopImage.height }}>
              <img
                src={responsiveSrcGenerator(desktopImage.url, desktopImage.width, desktopImage.height)}
                srcSet={srcSetGenerator(desktopImage.url, desktopImage.width, desktopImage.height)}
                width={Math.round(desktopImage.width / 2)}
                height={Math.round(desktopImage.height / 2)}
                alt={desktopImage.title}
                loading="lazy"
              />
            </div>
            )}
          {tabletImage && tabletImage.height && (
            <div className={`${styles.tabletImage} ${styles[imageFit]}`} style={{ maxHeight: tabletImage.height }}>
              <img
                src={responsiveSrcGenerator(tabletImage.url, tabletImage.width, tabletImage.height)}
                srcSet={srcSetGenerator(tabletImage.url, tabletImage.width, tabletImage.height)}
                width={Math.round(tabletImage.width / 2)}
                height={Math.round(tabletImage.height / 2)}
                alt={tabletImage.title}
                loading="lazy"
              />
            </div>
          )}
          {mobileImage && mobileImage.height
            && (
            <div className={`${styles.mobileImage} ${styles[imageFit]}`} style={{ maxHeight: mobileImage.height }}>
              <img
                src={responsiveSrcGenerator(mobileImage.url, mobileImage.width, mobileImage.height)}
                srcSet={srcSetGenerator(mobileImage.url, mobileImage.width, mobileImage.height)}
                width={Math.round(mobileImage.width / 2)}
                height={Math.round(mobileImage.height / 2)}
                alt={mobileImage.title}
                loading="lazy"
              />
            </div>
            )}
        </Link>
        {playIcon && (
          <span className={styles.play}>
            <svg width="41" height="40" viewBox="0 0 41 40" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect x="0.602539" width="40" height="40" rx="20" fill="#BEA2FF" />
              <path d="M25.6025 19.9999L17.6025 26V14L25.6025 19.9999Z" fill="#210234" />
            </svg>
          </span>
        )}
      </div>
      {ctaVisible && <div className={`${styles[alignment]} ${styles.cta}`}><Button {...cta} /></div>}
    </>
  );
};
