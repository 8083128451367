import React from 'react';
import { CTAType } from '@/constants/CTAType';
import { Mode } from '@/constants/Mode';
import { ITitle, Title } from './Title';
import { IText, Text } from './Text';
import { EyeBrow, IEyebrow } from './EyeBrow';
import { Button, IButton } from './Button';
import { IEngager, Engager } from './Engager';
import { TextAlignments } from '../constants/TextAlignments';
import { FunnelButton, IFunnelButton } from './FunnelButton';
import styles from './TextBlock.module.scss';

export interface ITextBlock {
  desktopAlignment?: TextAlignments | string;
  mobileAlignment?: TextAlignments | string;
  title?: ITitle;
  text?: IText;
  eyebrow?: IEyebrow;
  cta?: IButton;
  ctaVisible?: boolean;
  funnel?: IFunnelButton;
  ctaType?: CTAType | string;
  /** ACQ-3039 - Leaving props here prefixed with 'postCode' to avoid contentful updates */
  postCodeEngagerVisible?: boolean;
  postCodeEngager?: IEngager;

  mode?: Mode;
}

export const TextBlock = ({
  desktopAlignment = TextAlignments.LEFT,
  mobileAlignment = TextAlignments.LEFT,
  eyebrow,
  title,
  text,
  ctaVisible = false,
  cta,
  funnel,
  ctaType = CTAType.DEFAULT,
  /** ACQ-3039 - Leaving props here prefixed with 'postCode' to avoid contentful updates */
  postCodeEngagerVisible: engagerVisible = false,
  postCodeEngager: engager,
}: ITextBlock) => (
  <div className={styles.root}>
    {eyebrow && eyebrow.children && <EyeBrow {...eyebrow} />}
    {title && title.markdown && <Title {...title} />}
    {text && text.markdown && <Text {...text} />}
    {ctaVisible && (
    <div
      className={`${styles[`desktop-${desktopAlignment}`]} ${styles[`mobile-${mobileAlignment}`]} ${styles.cta}`}
    >
      {ctaType === CTAType.DEFAULT && <Button {...cta} />}
      {ctaType === CTAType.FUNNEL && <FunnelButton {...funnel} />}
    </div>
    )}
    {engagerVisible && (
    <div className={`${styles[`desktop-${desktopAlignment}`]} ${styles.engager} `}>
      <Engager {...engager} />
    </div>
    )}
  </div>
);
