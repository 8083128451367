/* eslint-disable no-param-reassign */
import { ATHENA_LENDING_PRODUCT } from '@/constants/AthenaLendingProduct';
import { CURRENT_LENDER } from '@/constants/CurrentLender';
import { FormInputNames } from '@/constants/FormInputNames';
import { PROPERTY_TYPE } from '@/constants/PropertyType';
import { REPAYMENT_FREQUENCY } from '@/constants/RepaymentFrequency';
import { MutableRefObject } from 'react';
import { LeadsRequest } from '../types/leadsRequest';
import { setData } from '../utilities/leadsObjAssign';

// Maps lead service enum for api integration
// if enum values are not found then dump data to leadData field

const setLeadEnums = (
  formInputNames: FormInputNames,
  enums: typeof ATHENA_LENDING_PRODUCT | typeof PROPERTY_TYPE | typeof REPAYMENT_FREQUENCY | typeof CURRENT_LENDER,
  fieldRefs: MutableRefObject<{
    [key: string]: HTMLInputElement | HTMLTextAreaElement | null;
  }>,
  data: LeadsRequest,
  dataField: keyof LeadsRequest,
) => {
  if (fieldRefs?.current[formInputNames]?.value) {
    const lend = fieldRefs.current[formInputNames]?.value ? fieldRefs.current[formInputNames]?.value : '';
    const leadsEnum = Object.values(enums)
      .filter((elem) => elem === lend)
      ?.pop();
    if (leadsEnum) {
      setData(dataField, leadsEnum, data);
    } else {
      data.leadData = data.leadData ? `${data.leadData} ${formInputNames}:${lend},` : `${formInputNames}:${lend},`;
    }
  }
};
export const assignEnumValue = (
  fieldRefs: MutableRefObject<{
    [key: string]: HTMLInputElement | HTMLTextAreaElement | null;
  }>,
  dataField: keyof LeadsRequest,
  data: LeadsRequest,
) => {
  switch (dataField) {
    case FormInputNames.ATHENA_LENDING_PRODUCT: {
      setLeadEnums(
        FormInputNames.ATHENA_LENDING_PRODUCT,
        ATHENA_LENDING_PRODUCT,
        fieldRefs,
        data,
        FormInputNames.ATHENA_LENDING_PRODUCT,
      );
      return data;
    }
    case FormInputNames.PROPERTY_TYPE: {
      setLeadEnums(
        FormInputNames.PROPERTY_TYPE,
        PROPERTY_TYPE,
        fieldRefs,
        data,
        FormInputNames.PROPERTY_TYPE,
      );
      return data;
    }
    case FormInputNames.REPAYMENT_FREQUENCY: {
      setLeadEnums(
        FormInputNames.REPAYMENT_FREQUENCY,
        REPAYMENT_FREQUENCY,
        fieldRefs,
        data,
        FormInputNames.REPAYMENT_FREQUENCY,
      );
      return data;
    }
    case FormInputNames.CURRENT_LENDER: {
      setLeadEnums(
        FormInputNames.CURRENT_LENDER,
        CURRENT_LENDER,
        fieldRefs,
        data,
        FormInputNames.CURRENT_LENDER,
      );
      return data;
    }
    default: {
      const lend = fieldRefs.current[dataField]?.value;
      data.leadData = data.leadData ? `${data.leadData} ${dataField}:${lend},` : `${dataField}:${lend},`;
      return data;
    }
  }
};
