import React, { useState } from 'react';
import { EngagerSizes } from '@/constants/EngagerSizes';
import { TextAlignments } from '@/constants/TextAlignments';
import { TabTypes } from '../constants/TabTypes';
import styles from './Engager.module.scss';
import { TabBar } from './TabBar';
import { Mode } from '../constants/Mode';
import { EngagerFunnelButton } from './EngagerFunnelButton';

export interface IEngager {
  args?: any;
  mode?: Mode | string;
  elevated?: Boolean;
  link?: string;
  size?: EngagerSizes;
  desktopAlignment?: TextAlignments | string;
  mobileAlignment?: TextAlignments | string;
}

export const Engager = ({
  mode = Mode.LIGHT,
  elevated = false,
  size = EngagerSizes.SMALL,
  desktopAlignment = TextAlignments.LEFT,
  mobileAlignment = TextAlignments.CENTER,
}: IEngager) => {
  const [active, setActive] = useState<number>(0);
  const links = ['/refinancing', '/buying', '/investing'];

  return (
    <div className={`${elevated ? styles.root : ''}`}>
      <div
        className={`${styles.tabBar}  ${styles[`desktop-${desktopAlignment}`]} ${styles[`mobile-${mobileAlignment}`]}`}
      >
        <TabBar
          active={active}
          setActive={setActive}
          type={TabTypes.INLINE}
          mode={mode}
          tabs={['Refinance', 'Buy', 'Invest']}
        />
      </div>
      <div
        className={`${styles.engagerBorderContainer} ${styles[`desktop-${desktopAlignment}`]} ${
          styles[`mobile-${mobileAlignment}`]
        }`}
      >
        <div className={`${styles.inputContainer} ${styles[size]}`}>
          {active === 0 && <div className={`${styles.textContainer}`}>See why you should switch</div>}
          {active === 1 && <div className={`${styles.textContainer}`}>Own your home sooner</div>}
          {active === 2 && <div className={`${styles.textContainer}`}>Show me the money</div>}
          <EngagerFunnelButton link={links[active]} />
        </div>
      </div>
    </div>
  );
};
