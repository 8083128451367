import React from 'react';
import styles from './FunnelButton.module.scss';
import { FunnelButtons } from '../constants/FunnelButtons';
import { ButtonSizes } from '../constants/ButtonSizes';

export interface IFunnelButton {
  children?: React.ReactNode | string;
  type?: FunnelButtons | string;
  size?: ButtonSizes | string;
  url?: string;
}

export const FunnelButton = ({
  children,
  type = FunnelButtons.PRIMARY,
  size = ButtonSizes.LARGE,
  url,
}: IFunnelButton) => (
  <a
    href={url || '/start'}
    className={`${styles.root} ${styles[type]}  ${styles[size]} ${children === '' ? styles.iconMode : ''} `}
  >
    <span className={styles.text}>{children}</span>
    <span className={styles.pink} />
    <span className={styles.icon} role="presentation">r</span>
  </a>
);
