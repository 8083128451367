import { IRate } from '@/queries/fetchRates';

type ConvertedIRateObj = Record<keyof IRate, string>;

export const convertRatesToStrings = (obj: IRate) => Object.keys(obj).reduce(
  (prev, key) => ({
    ...prev,
    [key]: obj[key as keyof IRate].toString(),
  }),
  {} as ConvertedIRateObj,
);
