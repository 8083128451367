import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { getAnchorFromUrl } from './getAnchorFromUrl';

export function useScrollAnchorIntoView() {
  const router = useRouter();

  useEffect(() => {
    const elementId = getAnchorFromUrl(router.asPath);
    const element = elementId ? document.querySelector(`#${elementId}`) : null;
    if (element) {
      setTimeout(() => element.scrollIntoView({ block: 'center', inline: 'center' }), 300);
    }
  }, [router.asPath]);
}
