/* eslint-disable react/jsx-indent */
/* eslint-disable no-unneeded-ternary */
import { FormInputNames } from '@/constants/FormInputNames';
import { FormInputTypes } from '@/constants/FormInputTypes';
import React, { LegacyRef } from 'react';
import { Mode } from '../Mode';
import styles from './FormTextInputs.module.scss';

export interface IFormTextInputs {
  fieldLabel: string;
  fieldType: string | FormInputTypes;
  fieldName: string | FormInputNames;
  optional: boolean;
  optionalText?: string;
  placeHolderIcon?: string;
  placeHolderText?: string;
  helperText?: string;
  errorMsg?: string;
  mode?: Mode | string;
  inputRef?: LegacyRef<HTMLInputElement | HTMLTextAreaElement>;
}

export const FormTextInputs = ({
  fieldLabel,
  fieldName, // this prop to be restricted in cms
  fieldType, // this prop to be restricted in cms [text, text-area, number]
  optional,
  optionalText,
  placeHolderIcon,
  placeHolderText,
  helperText,
  inputRef,
  errorMsg,
  mode = Mode.LIGHT,
}: IFormTextInputs) => (
  <div className={`${styles.root}`}>
    <div className={`${styles['label-optional-space']}`}>
      <label htmlFor={fieldName} className={`${styles['label-fonts']} ${styles[`label-${mode}`]}`}>
        {fieldLabel}
      </label>
      {optional && optionalText && <span className={`${styles[`optional-fonts-${mode}`]}`}>{optionalText}</span>}
    </div>
    {fieldType === FormInputTypes.INPUT_TEXT && (
      <div className={`${styles[`fields-icons-${mode}`]}`}>
        {placeHolderIcon && (
          <div className={`${styles['icon-input-fields']} ${styles[`icon-input-fields-${mode}`]}`}>
            {placeHolderIcon}
          </div>
        )}
        <input
          ref={inputRef as LegacyRef<HTMLInputElement>}
          aria-label={fieldLabel}
          name={fieldName}
          type={fieldType}
          placeholder={placeHolderText || ''}
          aria-placeholder={placeHolderText || ''}
          className={`${styles.fields}`}
        />
      </div>
    )}
    {fieldType === FormInputTypes.NUMBER && (
      <div className={`${styles[`fields-icons-${mode}`]}`}>
        {placeHolderIcon && (
          <div className={`${styles['icon-input-fields']} ${styles[`icon-input-fields-${mode}`]}`}>
            {placeHolderIcon}
          </div>
        )}
        <input
          ref={inputRef as LegacyRef<HTMLInputElement>}
          aria-label={fieldLabel}
          name={fieldName}
          type={fieldType}
          placeholder={placeHolderText || ''}
          aria-placeholder={placeHolderText || ''}
          className={`${styles.fields}`}
          step=".01"
        />
      </div>
    )}
    {fieldType === FormInputTypes.TEXT_AREA && (
      <div className={`${styles[`text-area-icons-wrapper-${mode}`]}`}>
        {placeHolderIcon && <div className={`${styles['icon-text-area']} ${styles[`${mode}`]}`}>{placeHolderIcon}</div>}
        <textarea
          ref={inputRef as LegacyRef<HTMLTextAreaElement>}
          aria-label={fieldLabel}
          name={fieldName}
          placeholder={placeHolderText ? placeHolderText : ''}
          aria-placeholder={placeHolderText ? placeHolderText : ''}
          className={`${styles['text-area']}`}
        />
      </div>
    )}
    {!errorMsg && helperText && <span className={`${styles[`helper-text-${mode}`]}`}>{helperText}</span>}
    {errorMsg && <span className={`${styles['error-msg']}`}>{errorMsg}</span>}
  </div>
);
