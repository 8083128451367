/* eslint-disable no-unused-vars */

export enum FormInputNames {
  ATHENA_BORROWING_AMOUNT = 'athenaBorrowingAmount',
  ATHENA_LOAN_TERM = 'athenaLoanTerm',
  ATHENA_LENDING_PRODUCT = 'athenaLendingProduct',
  CURRENT_LENDER = 'currentLender',
  CURRENT_LENDER_OTHER = 'currentLenderOther',
  CURRENT_LOAN_AMOUNT = 'existingLoanAmount',
  CURRENT_RATE = 'existingRate',
  DESCRIPTION = 'description',
  EMAIL = 'email',
  FIRST_NAME = 'firstName',
  SURNAME = 'surname',
  LOAN_AMOUNT = 'loanAmount',
  MOBILE_NUMBER = 'mobileNumber',
  NAME = 'name',
  POST_CODE = 'postcode',
  PROPERTY_TYPE = 'propertyType',
  PROPERTY_VALUE = 'propertyValue',
  REPAYMENT_AMOUNT = 'repaymentAmount',
  REPAYMENT_FREQUENCY = 'repaymentFrequency',
}
