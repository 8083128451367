import React from 'react';
import styles from './Eyebrow.module.scss';
import { Mode } from '../constants/Mode';
import { EyebrowTypes } from '../constants/EyebrowTypes';
import { TextAlignments } from '../constants/TextAlignments';
import { TextMarginBottom } from '../constants/TextMarginBottom';

export interface IEyebrow {
  desktopAlignment?: TextAlignments | string;
  mobileAlignment?: TextAlignments | string;
  children?: React.ReactNode | string;
  icon?: string;
  marginBottom?: TextMarginBottom | string;
  mode?: Mode | string;
  type?: EyebrowTypes | string;
}

export const EyeBrow = ({
  desktopAlignment = TextAlignments.LEFT,
  mobileAlignment = TextAlignments.LEFT,
  children,
  icon = '',
  marginBottom = TextMarginBottom.MEDIUM,
  mode = Mode.LIGHT,
  type = EyebrowTypes.LIGHTER,
}: IEyebrow) => (
  <p
    className={`${styles.root} ${styles[mode]} ${styles[type]} ${styles[mode]} ${
      styles[`desktop-${desktopAlignment}`]
    } ${styles[`mobile-${mobileAlignment}`]}
      ${styles[`margin-bottom-${marginBottom}`]}`}
  >
    <span className={styles.icon}>{icon}</span>
    {' '}
    {children}
  </p>
);
