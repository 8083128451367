import Image from 'next/image';
import React from 'react';
import { TextMarginBottom } from '@/constants/TextMarginBottom';
import { Button, IButton } from './Button';
import { type IGenericBannerImage } from './GenericBanner';
import { IText, Text } from './Text';
import { ITitle, Title } from './Title';
import styles from './VideoModalActive.module.scss';
import { imageLoader } from '../utilities/imageLoader';

export interface IVideoModalActive {
  title?: ITitle;
  text?: IText;
  cta?: IButton;
  backgroundImage?: IGenericBannerImage;
  relatedVideoText?: ITitle;
  handleClick?: (bool: boolean) => void;
  active?: boolean;
  activeVid?: number;
  activeRelatedVid?: number;
  videoListLength?: number;
  handleActiveRelatedVid?: (index: number) => void;
  handleActiveVid?: (index: number) => void;
}

function imgClickHandler(
  activeVid: number,
  activeRelatedVid: number,
  videoListLength: number,
  handleActiveVid: (index: number) => void,
  handleActiveRelatedVid: (index: number) => void,
) {
  if (activeVid + 1 >= videoListLength) {
    handleActiveVid(0);
  } else {
    handleActiveVid(activeVid + 1);
  }
  if (activeRelatedVid + 1 >= videoListLength) {
    handleActiveRelatedVid(0);
  } else {
    handleActiveRelatedVid(activeRelatedVid + 1);
  }
}

export const VideoModalActive = ({
  title,
  text,
  cta,
  active,
  handleClick,
  backgroundImage,
  activeRelatedVid, // next video always starts from 1
  activeVid,
  handleActiveRelatedVid,
  handleActiveVid,
  videoListLength,
  relatedVideoText,
}: IVideoModalActive) => (
  <div className={`${styles.root} ${active ? styles.active : styles.inactive}`}>
    <div
      className={`${styles.arr} ${active ? styles['arrow-active'] : ''}`}
      onClick={() => {
        if (handleClick) handleClick(false);
      }}
    >
      <div className={`${styles.arrow}`}>^</div>
    </div>
    <div className={styles.intro}>
      <Title {...title} />
      <Text {...text} />
      <div className={styles.btn}>
        <Button {...cta} />
      </div>
    </div>
    {videoListLength && videoListLength > 1 && (
      <div
        className={styles.img}
        onClick={() => {
          if (
            typeof activeVid === 'number'
                && typeof activeRelatedVid === 'number'
                && typeof videoListLength === 'number'
          ) {
            // value is definitely a number and not null
            if (
              activeVid >= 0
                  && activeRelatedVid >= 0
                  && videoListLength > 1
                  && handleActiveVid
                  && handleActiveRelatedVid
            ) {
              imgClickHandler(
                activeVid,
                activeRelatedVid,
                videoListLength,
                handleActiveVid,
                handleActiveRelatedVid,
              );
            }
          }
        }}
      >
        <div className={styles.relatedOverlay}>
          <p>Watch next video</p>
        </div>
        <Title {...relatedVideoText} marginBottom={TextMarginBottom.MEDIUM} />
        <Image
          src={backgroundImage ? backgroundImage.url : ''}
          loader={imageLoader}
          style={{ objectFit: 'cover' }}
          alt={backgroundImage ? backgroundImage.title : ''}
          width={344}
          height={220}
        />
      </div>
    )}
  </div>
);
