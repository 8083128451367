import { useState, useEffect } from 'react';

/* NextJS Implementation */
export const useMediaQuery = () => {
  const [isMobile, setIsMobile] = useState(false);

  const handleResize = (event?: MediaQueryListEvent) => {
    if (event) {
      setIsMobile(event.matches);
    } else {
      /* Manually trigger query if undefined event from server-side on initial load */
      const clientSideQuery = window.matchMedia('(max-width: 768px)');
      setIsMobile(clientSideQuery.matches);
    }
  };

  useEffect(() => {
    /* Register initial manual query on load */
    handleResize();

    /* Resister event listeners for subsequent resizes */
    const mediaQuery = window.matchMedia('(max-width: 768px)');
    mediaQuery.addEventListener('change', handleResize);

    return () => {
      mediaQuery.removeEventListener('change', handleResize);
    };
  }, []);

  return {
    isMobile,
  };
};
