import { nonStandardKeys, IDictionaryRate, standardKeys } from '@/queries/fetchRates';

export const getCalculatorRates = (rates: IDictionaryRate) => ({
  directRates: rates[nonStandardKeys.DIRECT],
  marketRates: rates[nonStandardKeys.BACK_BOOK],
  standardRates: rates[standardKeys.STANDARD],
  standardBasicsRates: rates[standardKeys.STANDARD_BASICS],
  standardExtrasRates: rates[standardKeys.STANDARD_EXTRAS],
  directBasicsRates: rates[nonStandardKeys.DIRECT_BASICS],
  directExtrasRates: rates[nonStandardKeys.DIRECT_EXTRAS],
  selectRates: rates[nonStandardKeys.SELECT],
});
