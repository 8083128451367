import React, { useState } from 'react';
import { TabTypes } from '@/constants/TabTypes';
import {
  standardKeys,
  nonStandardKeys,
  IDictionaryRate,
  LVRTier,
  propertyPurpose as PropertyPurpose,
  repaymentType as RepaymentType,
  IRate,
  FixedYears,
} from '@/queries/fetchRates';
import { convertRatesToStrings } from '../utilities/convertRatesToStrings';
import { TabBar } from './TabBar';
import { TabBarString } from './TabBarString';
import styles from './RateTable.module.scss';
import { Mode } from './Mode';
import { ITitle, Title } from './Title';

interface IRateTable {
  rates: IDictionaryRate;
  mode: string;
  repaymentType: string;
  propertyPurpose: string;
  classic: boolean;
  title?: ITitle;
  subtitle?: ITitle;
  id?: string;
}

interface ITier {
  ['Straight Up']: 'direct-basics';
  ['Power Up']: 'direct-extras';
  ['Fixed']: 'direct-basics';
  ['Classic']: 'direct';
}

interface IStandardTier {
  ['Straight Up']: 'standard-basics';
  ['Power Up']: 'standard-extras';
  ['Fixed']: 'standard-basics';
  ['Classic']: 'standard';
}

interface IlvrNames {
  [key: string]: 'Liberate' | 'Evaporate' | 'Celebrate' | 'Obliterate';
}

export const RateTable = ({
  rates,
  mode,
  repaymentType,
  propertyPurpose,
  classic = false,
  title,
  subtitle,
  id,
}: IRateTable) => {
  const [tierTab, setTierTab] = useState<'Straight Up' | 'Power Up' | 'Fixed' | 'Classic'>(
    classic ? 'Classic' : 'Straight Up',
  );
  const [fixedYear, setFixedYear] = useState<number>(0);
  const tier: ITier = {
    'Straight Up': 'direct-basics',
    'Power Up': 'direct-extras',
    Fixed: 'direct-basics',
    Classic: 'direct',
  };
  const standardTier: IStandardTier = {
    'Straight Up': 'standard-basics',
    'Power Up': 'standard-extras',
    Fixed: 'standard-basics',
    Classic: 'standard',
  };
  const lvrNames: IlvrNames = {
    '70-80': 'Liberate',
    '60-70': 'Evaporate',
    '50-60': 'Celebrate',
    '0-60': 'Celebrate',
    '0-50': 'Obliterate',
  };
  const productTier: 'direct-basics' | 'direct-extras' | 'direct' = tier[tierTab];
  // eslint-disable-next-line object-shorthand
  const elementProps = id ? { id: id } : {};
  return (
    <>
      {title && <Title {...title} />}
      {subtitle && <Title {...subtitle} />}
      <div {...elementProps} className={styles.root}>
        {classic === false && (
          <>
            <div className={styles.controlTabBar}>
              <TabBarString
                tabs={['Straight Up', 'Power Up', 'Fixed']}
                type={TabTypes.BUTTON}
                setActive={setTierTab}
                active={tierTab}
                stretch
                mode={Mode.LIGHT}
              />
            </div>
            {tierTab === 'Fixed' && (
              <TabBar
                tabs={Array.from(Object.keys(rates[tier.Fixed].FIXED_RATE), (year) => `${year} year`)}
                type={TabTypes.BUTTON}
                setActive={setFixedYear}
                active={fixedYear}
                stretch
                mode={Mode.LIGHT}
              />
            )}
          </>
        )}

        <div className="table">
          <table style={{ width: '100%' }}>
            <tbody>
              <tr>
                {mode === 'standard' && (
                  <>
                    <th>LVR Tier</th>
                    <th>Rate</th>
                    <th>Comparison Rate</th>
                  </>
                )}
                {mode === 'full' && (
                  <>
                    <th>LVR Tier</th>
                    <th>Rate</th>
                    <th>Comparison Rate</th>
                    <th>Reference Rate</th>
                    <th>LVR Discount</th>
                  </>
                )}
                {mode === 'broker' && (
                  <>
                    <th>LVR Tier</th>
                    <th>Rate</th>
                    <th>Comparison Rate</th>
                    <th>Revert Rate</th>
                  </>
                )}
              </tr>
              {tierTab !== 'Fixed' && (
                <>
                  {Object.keys(
                    rates[productTier].VARIABLE_RATE[repaymentType as RepaymentType][
                      propertyPurpose as PropertyPurpose
                    ],
                  )
                    .map((lvrTier: string, index: number) => {
                      const lvrObj = rates[productTier].VARIABLE_RATE[repaymentType as RepaymentType][
                        propertyPurpose as PropertyPurpose
                      ][lvrTier as LVRTier];
                      const stndObj = rates[standardTier[tierTab]].VARIABLE_RATE[repaymentType][propertyPurpose];

                      const { rate, comparisonRate } = convertRatesToStrings(lvrObj as IRate);

                      return (
                        <tr key={index}>
                          <td>
                            <p style={{ marginBottom: lvrNames[lvrTier] ? '8px' : '0' }}>
                              {lvrTier}
                              %
                            </p>
                            {lvrNames[lvrTier] && <p style={{ color: 'black' }}>{lvrNames[lvrTier]}</p>}
                          </td>
                          <td>
                            {(parseFloat(rate) * 100).toFixed(2)}
                            %
                          </td>
                          {comparisonRate && (
                          <td>
                            {(parseFloat(comparisonRate) * 100).toFixed(2)}
                            %
                          </td>
                          )}
                          {mode === 'full' && (
                            <>
                              <td>
                                {(parseFloat(stndObj.rate.toString()) * 100).toFixed(2)}
                                %
                              </td>
                              <td>
                                {(parseFloat(stndObj.rate.toString()) * 100 - parseFloat(rate) * 100).toFixed(2)}
                                %
                              </td>
                            </>
                          )}
                          {mode === 'broker' && <td>N/A</td>}
                        </tr>
                      );
                    })}
                </>
              )}
              {tierTab === 'Fixed' && (
                <>
                  {Object.keys(
                    rates[nonStandardKeys.DIRECT_BASICS].FIXED_RATE[`${fixedYear + 1}` as FixedYears][
                      repaymentType as RepaymentType
                    ][propertyPurpose as PropertyPurpose],
                  )
                    .map((lvrTier: string, index: number) => {
                      const lvrObj = rates[nonStandardKeys.DIRECT_BASICS].FIXED_RATE[`${fixedYear + 1}` as FixedYears][
                        repaymentType as RepaymentType
                      ][propertyPurpose as PropertyPurpose][lvrTier as LVRTier];

                      // eslint-disable-next-line max-len
                      const stndObj = rates[standardKeys.STANDARD].FIXED_RATE[fixedYear + 1][repaymentType][propertyPurpose];
                      let revertRate: IRate | { rate: 'N/A' } = { rate: 'N/A' };

                      if (
                        rates[productTier].VARIABLE_RATE[repaymentType as RepaymentType][
                          propertyPurpose as PropertyPurpose
                        ][lvrTier as LVRTier]
                      ) {
                        revertRate = rates[productTier].VARIABLE_RATE[repaymentType as RepaymentType][
                          propertyPurpose as PropertyPurpose
                        ][lvrTier as LVRTier] as IRate;
                      }

                      const { rate, comparisonRate } = convertRatesToStrings(lvrObj as IRate);

                      return (
                        <tr key={index}>
                          <td>
                            <p style={{ marginBottom: lvrNames[lvrTier] ? '8px' : '0' }}>
                              {lvrTier}
                              %
                            </p>
                            {lvrNames[lvrTier] && <p style={{ color: 'black' }}>{lvrNames[lvrTier]}</p>}
                          </td>
                          <td>
                            {(parseFloat(rate) * 100).toFixed(2)}
                            %
                          </td>
                          {comparisonRate && (
                          <td>
                            {(parseFloat(comparisonRate) * 100).toFixed(2)}
                            %
                          </td>
                          )}
                          {mode === 'full' && (
                            <>
                              <td>
                                {(parseFloat(stndObj.rate.toString()) * 100).toFixed(2)}
                                %
                              </td>
                              <td>
                                {(parseFloat(stndObj.rate.toString()) * 100 - parseFloat(rate) * 100).toFixed(2)}
                                %
                              </td>
                            </>
                          )}
                          {mode === 'broker' && (
                            <td>
                              {(
                                parseFloat(typeof revertRate.rate !== 'string' ? revertRate.rate.toString() : 'N/A')
                                * 100
                              ).toFixed(2) === 'NaN'
                                ? 'N/A '
                                : `${(
                                  parseFloat(
                                    typeof revertRate.rate !== 'string' ? revertRate.rate.toString() : 'N/A',
                                  ) * 100
                                ).toFixed(2)}%`}
                            </td>
                          )}
                        </tr>
                      );
                    })}
                </>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};
