import React from 'react';
import dynamic from 'next/dynamic';
import { IDictionaryRate } from '@/queries/fetchRates';
import { getRepaymentCalculatorRates } from 'logic/getRepaymentCalculatorRates';
import { getCalculatorRates } from 'logic/getCalculatorRates';

const BorrowingPowerWrapper = dynamic(
  () => import('./borrowingCalculator/components/BorrowingPowerWrapper').then((comp) => comp.BorrowingPowerWrapper),
  {
    ssr: false,
  },
);

const GBSTWrapper = dynamic(() => import('./refinanceCalculator/GBSTWrapper').then((comp) => comp.GBSTWrapper), {
  ssr: false,
});

const TrustPilotCarousel = dynamic(() => import('./TrustPilotCarousel').then((comp) => comp.TrustPilotCarousel), {
  ssr: false,
});

const RateCalculatorWrapper = dynamic(
  () => import('@athenamortgages/athena-calculator-components').then((comp) => comp.RateCalculator),
  {
    ssr: false,
  },
);

const RepaymentCalculatorWrapper = dynamic(
  () => import('@athenamortgages/athena-calculator-components').then((comp) => comp.RepaymentCalculator),
  {
    ssr: false,
  },
);

const EquityCalculatorWrapper = dynamic(
  () => import('@athenamortgages/athena-calculator-components').then((comp) => comp.EquityCalculator),
  {
    ssr: false,
  },
);

interface IRenderComponent {
  component: string;
  rates: IDictionaryRate;
  anchorId?: string;
}

export const RenderComponent = ({ component, rates, anchorId }: IRenderComponent) => {
  const elementProps = anchorId ? { id: anchorId } : {};
  return (
    <div {...elementProps}>
      {component === 'Refinance Calculator' && <GBSTWrapper />}
      {component === 'Borrowing Power' && <BorrowingPowerWrapper rates={rates} />}
      {component === 'Rate Calculator' && (
        <RateCalculatorWrapper rates={getCalculatorRates(rates)} />
      )}
      {component === 'Repayment Calculator' && (
      <RepaymentCalculatorWrapper rates={getRepaymentCalculatorRates(rates)} />
      )}
      {/* TODO: Operational Improvement work to align public-ui types with common-logic types */}
      {/* @ts-ignore */}
      {component === 'Equity Calculator' && <EquityCalculatorWrapper rates={getCalculatorRates(rates)} />}
      {component === 'Trust Pilot Light' && <TrustPilotCarousel type="light" />}
      {component === 'Trust Pilot Dark' && <TrustPilotCarousel type="dark" />}
    </div>
  );
};
