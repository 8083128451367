import React from 'react';
import styles from './TabBar.module.scss';
import { Tab } from './Tab';
import { TabTypes } from '../constants/TabTypes';
import { Mode } from '../constants/Mode';

export interface ITabBar {
  setActive: (index: number) => void;
  active: number | null;
  mode: Mode | string;
  tabs: string[];
  type: TabTypes;
  stretch?: boolean;
}

export const TabBar = ({
  active, mode, setActive, tabs, type, stretch = false,
}: ITabBar) => (
  <div className={`${styles.root} ${styles[type]} ${stretch === true ? styles.stretch : styles['no-stretch']}`}>
    {tabs.map((tab, i) => (
      <Tab
        active={active === i}
        key={i}
        index={i}
        mode={mode}
        clickHander={setActive}
        type={type}
        stretch={stretch}
      >
        {tab}
      </Tab>
    ))}
  </div>
);
