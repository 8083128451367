import React, { useState } from 'react';
import { usePathname } from 'next/navigation';
import dynamic from 'next/dynamic';

import { COHORT, Cohorts } from '../../constants/cohorts';

/* Components */
import { Button } from '../Button';
import { HomePageHero } from './HomePageHero';
import { ScrollPercentage } from './ScrollPercentage';
import { CohortSelectButton } from './HomePageCohortButton';
import { HomePageBannerBackgroundSlant } from './HomePageBannerBackgroundSlant';
import { HomeOwnerGridComponent, InvestorGridComponent } from './HomePageBannerGridContent';
import { CohortContent, homeOwnerContent, investorContent } from './HomePageBannerContent';

/* SCSS */
import styles from './HomePageBanner.module.scss';

/* Hooks */
import { useMediaQuery } from './hooks/useMediaQuery';

const { INVESTORS, HOME_OWNERS } = Cohorts;

const ReactPlayer = dynamic(() => import('react-player'), { ssr: false });

export const HomePageBanner = () => {
  const pathname = usePathname();
  const { isMobile } = useMediaQuery();

  const [isVideoMounted, setIsVideoMounted] = useState(false);

  const isHomeOwnerCohort = pathname === '/';
  const isInvestorCohort = pathname === '/investors';

  const content: CohortContent = isHomeOwnerCohort ? homeOwnerContent : investorContent;
  const {
    title, underlinedContent, description, videoUrl, ctas, backgroundColor: bg, switchCohort,
  } = content;

  const GridContent = () => {
    if (isInvestorCohort) return InvestorGridComponent;
    if (isHomeOwnerCohort) return HomeOwnerGridComponent;

    return null;
  };

  /* Parallax Styles for Desktop/Mobile */

  /* Lower divisor for faster moving elements off the screen */
  const getParallaxStyles = (percentage: number, divisor = 2) => ({
    transition: '0.3s ease-out',
    transform: isMobile ? '' : `translateY(${percentage / divisor}vh)`,
  });

  const getOpacityStyles = (percentage: number) => ({
    opacity: isMobile ? '' : `${100 - Math.abs(percentage)}%`,
  });

  const getVideoPlayerTransitionStyles = () => (isMobile
    ? {}
    : {
      transition: '0.5s ease-in-out',
      opacity: isVideoMounted ? 1 : 0,
    });

  const getVideoPlayerStyles = (percentage: number) => (isMobile
    ? {}
    : {
      transformOrigin: 'center',
      transition: '0.1s ease-out',
      transform: `translateY(${percentage / 2}vh) scale(${1 - percentage / 100})`,
    });

  const getSwitchCohortStyles = (percentage: number) => {
    const percentageZero = (-1 * percentage) <= 0;
    return (isMobile
      ? {}
      : {
        height: percentageZero ? 40 : 0,
        marginTop: percentageZero ? -8 : 0,
        opacity: percentageZero ? 1 : 0,
        paddingBottom: percentageZero ? 48 : 0,
      });
  };

  const handleSelectCohort = (cohort: Cohorts) => {
    localStorage.setItem(COHORT, cohort);
  };

  return (
    <ScrollPercentage initial={0} threshold={0.7}>
      {({ percentage }: { percentage: number }) => (
        <div className={`${styles.root}`} style={{ background: bg }}>
          {/* Cohort button container */}
          <div className={`${styles.cohortButtonContainer}`}>
            <CohortSelectButton active={isHomeOwnerCohort} href="/" label="Home owner" onClick={() => handleSelectCohort(HOME_OWNERS)} />
            <CohortSelectButton active={isInvestorCohort} href="/investors" label="Investor" onClick={() => handleSelectCohort(INVESTORS)} />
          </div>
          <div className={`${styles.container}`}>
            {/* Container to reverse the layout on mobile to shift grid below content */}
            <div className={`${styles.layout}`}>
              {/* Grid Content */}
              <div style={{ ...getParallaxStyles(percentage, 1) }}>
                <GridContent />
              </div>

              {/* Home page hero content. Need this DOM structure for reverse layout */}
              <div>
                <div style={{ ...getParallaxStyles(percentage, 1) }}>
                  <HomePageHero
                    title={title}
                    description={description}
                    underlinedContent={underlinedContent}
                    opacityStyles={getOpacityStyles(percentage)}
                  />
                </div>

                {/* CTA Container */}
                <div style={{ ...getParallaxStyles(percentage), transition: '0.1s ease-out' }}>
                  <div className={`${styles.ctaContainer}`}>
                    {ctas.map(({ label, href }) => (
                      <Button key={label} mode="dark" href={href}>
                        {label}
                      </Button>
                    ))}
                  </div>
                  <div className={`${styles.switchCohortContainer}`} style={getSwitchCohortStyles(percentage)}>
                    <Button
                      type="outline"
                      onClick={() => {
                        handleSelectCohort(switchCohort.cohort);
                      }}
                      mode="dark"
                      href={switchCohort.href}
                    >
                      {switchCohort.label}
                    </Button>
                  </div>
                  <hr className={`${styles.horizontalDivider}`} />
                </div>
              </div>
            </div>

            {/* VideoPlayer  */}
            <div style={{ ...getVideoPlayerStyles(percentage) }} className={`${styles.videoPlayer}`}>
              <div className={`${styles.videoPlaceholder}`}>
                <div style={{ ...getVideoPlayerTransitionStyles() }}>
                  <ReactPlayer
                    loop
                    muted
                    playing
                    playsinline
                    width="100%"
                    height="100%"
                    url={videoUrl}
                    onReady={() => {
                      setIsVideoMounted(true);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>

          <HomePageBannerBackgroundSlant percentage={percentage} />
        </div>
      )}
    </ScrollPercentage>
  );
};
