import React from 'react';
import { Mode } from '../constants/Mode';
import styles from './AccordianTitle.module.scss';

export interface IAccordianTitle {
  children?: React.ReactNode;
  icon?: string;
  args?: any;
  active?: boolean;
  mode?: string;
  handleClick: (bool: boolean) => void;
}

export const AccordianTitle = ({
  children, active = false, handleClick, mode = Mode.LIGHT,
}: IAccordianTitle) => (
  <div onClick={() => handleClick(!active)}>
    <span className={`${styles.root} ${styles[mode]}`}>
      <span className={`${styles.title} ${styles[mode]}`}>
        {' '}
        {children}
        {' '}
      </span>
      <span className={` ${styles.icon} ${styles[mode]} ${active ? styles.active : ''}`} />
    </span>
  </div>
);
