import dynamic from 'next/dynamic';
import { Mode } from '@/constants/Mode';

const TrustBoxUI = dynamic(() => import('./TrustBoxUI').then((comp) => comp.TrustBoxUI), {
  ssr: false,
});

interface ITrustBox {
  mode: Mode | string;
}

export const TrustBox = ({ mode }: ITrustBox) => <TrustBoxUI mode={mode} />;
