import React, { useState } from 'react';
import { IBannerVideo } from './Banner';
import { Button, IButton } from './Button';
import { type IGenericBannerImage } from './GenericBanner';
import { IText, Text } from './Text';
import { ITitle, Title } from './Title';
import styles from './VideoModal.module.scss';
import { type IVideoModalActive, VideoModalActive } from './VideoModalActive';

export interface IVideoModal {
  handleClick?: (bool: boolean) => void;
  introTitle?: ITitle;
  introText?: IText;
  backText?: IText;
  backBtnBg?: string;
  backBtnColor?: string;
  videoModalActive?: IVideoModalActive;
  cta?: IButton;
  vid: IBannerVideo;
  backgroundImage?: IGenericBannerImage;
  relatedVideoText?: ITitle;

  activeVid?: number;
  activeRelatedVid?: number;
  handleActiveRelatedVid?: (index: number) => void;
  handleActiveVid?: (index: number) => void;
  videoListLength?: number;
  anchorId?: string;
}

export const VideoModal = ({
  handleClick,
  introTitle,
  introText,
  backText,
  backBtnColor = 'var(--deep-purple)',
  backBtnBg = 'var(--white)',
  videoModalActive,
  activeVid,
  handleActiveVid,
  activeRelatedVid,
  handleActiveRelatedVid,
  cta,
  vid,
  videoListLength,
  backgroundImage,
  relatedVideoText,
  anchorId,
}: IVideoModal) => {
  const [active, setActive] = useState<boolean>(false);
  const elementProps = anchorId ? { id: anchorId } : {};
  return (
    <>
      <div className={styles.fade} />
      <div {...elementProps} className={styles.root}>
        <div className={`${styles['cross-holder']}`}>
          <div
            className={styles.icon}
            style={{ backgroundColor: `${backBtnBg}`, color: `${backBtnColor}` }}
            onClick={() => {
              if (handleClick && handleActiveRelatedVid && handleActiveVid) {
                handleClick(false);
                handleActiveRelatedVid(1);
                handleActiveVid(0);
              }
            }}
          />
        </div>
        {active && (
          <div className={`${styles['btn-holder']}`}>
            <div
              className={styles.back}
              onClick={() => {
                setActive(false);
              }}
              style={{ backgroundColor: `${backBtnBg}`, color: `${backBtnColor}` }}
            />
            <div className={styles.play}>
              <Text {...backText} />
            </div>
          </div>
        )}

        <div className={styles.wrapper}>
          <div className={styles.ratio}>
            <video className={styles.video} controls title={vid.title}>
              <source src={vid.src.url} />
            </video>
            {active && (
            <div className={styles.overlay} />
            )}
          </div>
          <VideoModalActive
            {...videoModalActive}
            active={active}
            handleClick={setActive}
            activeRelatedVid={activeRelatedVid}
            activeVid={activeVid}
            handleActiveRelatedVid={handleActiveRelatedVid}
            handleActiveVid={handleActiveVid}
            videoListLength={videoListLength}
            backgroundImage={backgroundImage || undefined}
            cta={cta}
            relatedVideoText={relatedVideoText}
            title={introTitle}
          />
          <div className={`${active ? styles.inactive : styles.active}`}>
            <div className={`${styles.btn} ${active ? styles['arrow-active'] : ''}`} onClick={() => setActive(true)}>
              <div className={`${styles.arrow}`}>^</div>
            </div>
            <div className={`${styles.intro}`}>
              <Title {...introTitle} />
              <Text {...introText} />
              <Button {...cta} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
