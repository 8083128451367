import React from 'react';
import styles from './Icon.module.scss';
import { Mode } from '../constants/Mode';

export interface Iicon {
  children?: React.ReactNode;
  mode?: Mode;
  size?: string;
}

export const Icon = ({ children, mode = Mode.LIGHT, size = 'default' }: Iicon) => <span className={`${styles.root} ${styles[mode]} ${styles[size]}`}>{children}</span>;
