import React from 'react';
import { Mode } from '../constants/Mode';
import styles from './Legal.module.scss';
import { Accordian } from './Accordian';
import { Title } from './Title';
import { HeadingSizes } from '../constants/HeadingSizes';
import { Text } from './Text';
import { TextSizes } from '../constants/TextSizes';
import { Container } from './Container';
import { ContainerSizes } from '../constants/ContainerSizes';
import { SingleAccordianFragment } from '../types/schema';

export interface ILegal {
  legalHeading: string;
  legalComparisonBlock: string;
  accordians: SingleAccordianFragment;
  mode?: Mode;
}

export const Legal = ({
  legalHeading = '', legalComparisonBlock = '', accordians, mode = Mode.DARK,
}: ILegal) => (
  <div className={`${styles.dark} ${styles.root}`}>
    <Container size={ContainerSizes.LARGE}>
      <div className={`${styles.legalHeaderContainer}`}>
        {legalHeading && (
        <Title isBold mode={mode} size={HeadingSizes.LARGE}>
          {legalHeading}
        </Title>
        )}
        {legalComparisonBlock && (
        <div className={`${styles.comparison}`}>
          <Text mode={mode} size={TextSizes.EXTRASMALL}>
            {legalComparisonBlock}
          </Text>
        </div>
        )}
      </div>
      <Accordian title={accordians.title} bodyCollection={accordians.bodyCollection.items} mode={Mode.DARK} />
      <div className={`${styles.line}`}>
        <hr />
      </div>
    </Container>
  </div>
);
