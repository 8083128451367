import React from 'react';
import styles from './EngagerFunnelButton.module.scss';
import { FunnelButtons } from '../constants/FunnelButtons';
import { ButtonSizes } from '../constants/ButtonSizes';

export interface IEngagerFunnelButton {
  children?: React.ReactNode;
  type?: FunnelButtons;
  size?: ButtonSizes;
  link?: string;
}

// Button size for this is only large
export const EngagerFunnelButton = ({
  children,
  type = FunnelButtons.PRIMARY,
  size = ButtonSizes.LARGE,
  link = '#',
}: IEngagerFunnelButton) => (
  <button
    type="button"
    className={`${styles.root} ${styles[type]}  ${styles[size]} ${children === '' ? styles.iconMode : ''} `}
    onClick={() => {
      window.location.href = link;
    }}
  >
    <span className={styles.pink} />
    <span className={styles.icon}>r</span>
  </button>
);
