import React from 'react';
import { Paddings } from '@/constants/Paddings';
import { AccentedBackgrounds } from '@/constants/AccentedBackgrounds';
import styles from './PageBlock.module.scss';
import { Slanted } from '../constants/Slanted';
import { Background } from '../constants/Background';

interface IPageBlock {
  bg?: string;
  slantedbg?: string;
  clippedbg?: string;
  desktopPaddingTop?: Paddings | string;
  desktopPaddingBottom?: Paddings | string;
  mobilePaddingTop?: Paddings | string;
  mobilePaddingBottom?: Paddings | string;
  children: React.ReactNode | React.ReactNode[];
  slanted?: Slanted | string;
  background?: Background;
  accentedBackground?: AccentedBackgrounds;
}

export const PageBlock = ({
  bg = 'var(--white)',
  slantedbg,
  clippedbg = 'linear-gradient(180deg, var(--mid-purple) 0%, #210234 70%)',
  children,
  desktopPaddingTop = Paddings.LARGE,
  desktopPaddingBottom = Paddings.LARGE,
  mobilePaddingTop = Paddings.LARGE,
  mobilePaddingBottom = Paddings.LARGE,
  background = Background.NONE,
  slanted = Slanted.NONE,
  accentedBackground = AccentedBackgrounds.NONE,
}: IPageBlock) => (
  <div
    className={`${styles.root} 
      ${styles[`desktop-${desktopPaddingTop}-top`]}
      ${styles[`desktop-${desktopPaddingBottom}-bottom`]}  
      ${styles[`mobile-${mobilePaddingTop}-top`]} 
      ${styles[`mobile-${mobilePaddingBottom}-bottom`]}`}
    style={{ background: bg }}
  >
    <div className={`${styles[accentedBackground]}`} />
    <div className={`${styles[background]}`} style={{ background: clippedbg }} />
    {children}
    <div className={`${styles.slant} ${styles[slanted]}`} style={{ background: slantedbg || bg }} />
  </div>
);
