import React from 'react';
import { responsiveSrcGenerator } from 'utilities/responsiveSrcGenerator';
import styles from './CardVariant.module.scss';
import { Title } from './Title';
import { Button } from './Button';
import { Text } from './Text';
import { Column } from './Column';
import { CardVariantFragment } from '../types/schema';
import { srcSetGenerator } from '../utilities/srcSetGenerator';

export interface ICardVariant {
  card: CardVariantFragment;
  anchorId?: string
}
export const CardVariant = ({ card, anchorId }: ICardVariant) => {
  const elementProps = anchorId ? { id: anchorId } : {};
  return (
    <Column xxs={card.mobileImageWidth} lg={card.desktopImageWidth} style={{ alignSelf: 'auto', marginBottom: '24px' }}>
      <div
        {...elementProps}
        className={`${styles.root} ${card.elevated ? styles.elevated : ''}`}
        style={card.background ? { background: `${card.background}` } : {}}
      >
        <div className={styles.image} style={{ justifyContent: `${card.contentAlignment}` }}>
          {card && card.image && card.image.url && (
          <>
            {card.mobileImage && card.mobileImage.url && (
            <div className={styles.desktopImage}>
              <img
                src={responsiveSrcGenerator(card.image.url, card.image.width, card.image.height)}
                srcSet={srcSetGenerator(card.image.url, card.image.width, card.image.height)}
                style={{ objectFit: 'contain' }}
                width={Math.round(card.image.width / 2)}
                height={Math.round(card.image.height / 2)}
                alt={card.image.title}
                loading="lazy"
              />
            </div>
            )}
            {card.mobileImage == null && (
            <img
              src={responsiveSrcGenerator(card.image.url, card.image.width, card.image.height)}
              srcSet={srcSetGenerator(card.image.url, card.image.width, card.image.height)}
              style={{ objectFit: 'contain' }}
              width={Math.round(card.image.width / 2)}
              height={Math.round(card.image.height / 2)}
              alt={card.image.title}
              loading="lazy"
            />
            )}
          </>
          )}

          {card && card.mobileImage && card.mobileImage.url && (
          <div className={styles.mobileImage}>
            <img
              src={responsiveSrcGenerator(card.mobileImage.url, card.mobileImage.width, card.mobileImage.height)}
              srcSet={srcSetGenerator(card.mobileImage.url, card.mobileImage.width, card.mobileImage.height)}
              style={{ objectFit: 'contain' }}
              width={Math.round(card.mobileImage.width / 2)}
              height={Math.round(card.mobileImage.height / 2)}
              alt={card.mobileImage.title}
              loading="lazy"
            />
          </div>
          )}

          <Title {...card.titleText} />
          <Text {...card.body} />
          {card.ctaVisible && (
          <div className={styles.btn}>
            {card.btnTextVisible && <div className={styles.text}>{card.btnText}</div>}
            <Button {...card.cta} />
          </div>
          )}
        </div>
      </div>
    </Column>
  );
};
