import React from 'react';
import styles from './Tab.module.scss';
import { Mode } from '../constants/Mode';
import { TabTypes } from '../constants/TabTypes';

export interface ITab {
  active?: boolean;
  args?: any;
  index: number;
  children?: React.ReactNode;
  mode?: Mode | string;
  type: TabTypes;
  clickHander: (index: number) => void;
  stretch: boolean;
}

export const Tab = ({
  active = true,
  children,
  clickHander,
  index = 0,
  mode = Mode.LIGHT,
  type = TabTypes.INLINE,
  stretch = false,
}: ITab) => (
  <div
    className={`${styles.root} ${active ? styles.active : ''} ${styles[mode]} ${styles[type]}
      ${stretch === true ? styles.stretch : styles['no-stretch']}`}
    onClick={() => {
      clickHander(index);
    }}
  >
    {children}
  </div>
);
