/* Styles */
import styles from './HomePageBanner.module.scss';

/* Components || Types */
import { CohortContent } from './HomePageBannerContent';

type HomePageHeroProps = Pick<CohortContent, 'title' | 'description' | 'underlinedContent'> & {
  opacityStyles: {
    opacity: string;
  };
};

export const HomePageHero = ({
  opacityStyles, title, underlinedContent, description,
}: HomePageHeroProps) => (
  <div>
    <div style={{ ...opacityStyles }} className={`${styles.titleContainer}`}>
      <h2 className={`${styles.title}`}>
        {title}
        <br />
        {/* Underline sticks to this word */}
        <span className={`${styles.underline}`}>{underlinedContent}</span>
      </h2>

      {/* Description */}
      <div className={`${styles.description}`}>{description}</div>
    </div>
  </div>
);
