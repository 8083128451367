import React from 'react';
import styles from './Tab.module.scss';
import { Mode } from '../constants/Mode';
import { TabTypes } from '../constants/TabTypes';

export interface ITab {
  active?: boolean;
  args?: any;
  tab: 'Straight Up' | 'Power Up' | 'Fixed';
  children?: React.ReactNode;
  mode?: Mode | string;
  type: TabTypes;
  clickHander: (index: 'Straight Up' | 'Power Up' | 'Fixed') => void;
  stretch: boolean;
}

export const StringTab = ({
  active = true,
  children,
  clickHander,
  tab = 'Straight Up',
  mode = Mode.LIGHT,
  type = TabTypes.INLINE,
  stretch = false,
}: ITab) => (
  <div
    className={`${styles.root} ${active ? styles.active : ''} ${styles[mode]} ${styles[type]}
      ${stretch === true ? styles.stretch : styles['no-stretch']}`}
    onClick={() => {
      clickHander(tab);
    }}
  >
    {children}
  </div>
);
