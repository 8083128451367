import * as React from 'react';

/* Components */
import { TrustBox } from '../TrustBox';

/* Styles */
import styles from './HomePageBanner.module.scss';

interface GridItemProps {
  imgAlt?: string;
  alignment?: string;
  imageSrc?: string;
  superScript?: string;
  subline: React.ReactNode | string;
  headline?: React.ReactNode | string;
}

const GridItem = ({
  headline = '',
  subline,
  imageSrc = '',
  imgAlt = '',
  superScript = '',
  alignment = '',
}: GridItemProps) => {
  const img = imageSrc && <img src={imageSrc} alt={imgAlt} width="60" height="60" loading="lazy" />;

  return (
    <li style={alignment ? { alignSelf: alignment } : {}} className={`${styles.gridItem}`}>
      <span className={`${styles.headline}`}>{img || headline}</span>
      <span className={`${styles.subline}`}>
        {subline}
        {superScript && <sup>{superScript}</sup>}
      </span>
    </li>
  );
};

export const HomeOwnerGridContent = (
  <>
    <GridItem
      headline="$704m"
      subline={(
        <>
          customer
          <br />
          savings **
        </>
      )}
    />

    <li role="presentation" className={`${styles.verticalDivider}`} />

    {/* Grid Item */}
    <GridItem
      alignment="flex-start"
      imageSrc="/next-assets/images/logos/finder.svg"
      imgAlt="finder"
      subline="#1 digital lender 2023"
      superScript="~"
    />

    <li role="presentation" className={`${styles.verticalDivider}`} />

    <GridItem
      headline="15 mins"
      subline={(
        <>
          to apply
          <br />
          online
        </>
      )}
    />

    <li role="presentation" className={`${styles.verticalDivider}`} />

    {/* Grid Item */}
    <li className={`${styles.gridItem} ${styles.trustPilot}`}>
      <TrustBox mode="dark" />
    </li>
  </>
);

export const InvestorGridContent = (
  <>
    {/* Rate container */}
    <li className={`${styles.gridItem}`}>
      <span>
        <p className={`${styles.caption}`}>RATES FROM</p>
        <div className={`${styles.rateContainer}`}>
          <span className={`${styles.headline}`}>6.09</span>
          <span className={`${styles.ratePercentage}`}>
            %
            <br />
            pa
          </span>
        </div>
      </span>
      <span className={`${styles.subline}`}>
        variable +
        <br />
        comparison ^
      </span>
    </li>

    <li role="presentation" className={`${styles.verticalDivider}`} />

    <GridItem
      headline="$704m"
      subline={(
        <>
          customer
          <br />
          savings **
        </>
      )}
    />

    <li role="presentation" className={`${styles.verticalDivider}`} />

    {/* Grid Item */}

    <GridItem
      headline="60 sec"
      superScript="#"
      subline={(
        <>
          conditional
          <br />
          approval
        </>
      )}
    />

    <li role="presentation" className={`${styles.verticalDivider}`} />

    {/* Grid Item */}
    <li className={`${styles.gridItem} ${styles.trustPilot}`}>
      <TrustBox mode="dark" />
    </li>
  </>
);

const withGridContainer = (children: React.ReactNode) => <ul className={`${styles.gridContainer}`}>{children}</ul>;

export const HomeOwnerGridComponent = withGridContainer(HomeOwnerGridContent);
export const InvestorGridComponent = withGridContainer(InvestorGridContent);
