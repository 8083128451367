import { IFormTextInputs } from '@/components/leadsForms/FormTextInputs';
import { FormInputNames } from '@/constants/FormInputNames';
import {
  EMAIL_REGEX, MOBILE_PHONE_REGEX, NAME_REGEX, POSTAL_CODE_REGEX,
} from '@/constants/FormInputsRegex';
import { FormInputTypes } from '@/constants/FormInputTypes';
import { MutableRefObject } from 'react';

export interface IFormValidation {
  textInputs: IFormTextInputs[];
  setErrors: (err: { [key: string]: string }) => void;
  fieldRefs: MutableRefObject<{
    [key: string]: HTMLInputElement | HTMLTextAreaElement | null;
  }>;
}

export const formInputValidation = ({ textInputs, fieldRefs, setErrors }: IFormValidation) => {
  const errors: Record<string, string> = {};
  textInputs.forEach((item) => {
    if (!item.optional && !fieldRefs?.current[item.fieldName]?.value?.trim()) {
      errors[item.fieldName] = 'This field is required';
    }
    if (item.fieldName === FormInputNames.POST_CODE) {
      if (
        fieldRefs?.current[item.fieldName]?.value?.trim()
        && !POSTAL_CODE_REGEX.test(fieldRefs?.current[item.fieldName]?.value as string)
      ) {
        errors[item.fieldName] = 'Postcode is invalid';
      }
    }
    if (item.fieldName === FormInputNames.MOBILE_NUMBER) {
      if (
        fieldRefs?.current[item.fieldName]?.value?.trim()
        && !MOBILE_PHONE_REGEX.test(fieldRefs?.current[item.fieldName]?.value as string)
      ) {
        errors[item.fieldName] = 'Please enter a valid Australian mobile number beginning with +614 or 04';
      }
    }
    if (item.fieldName === FormInputNames.EMAIL) {
      if (
        fieldRefs?.current[item.fieldName]?.value?.trim()
        && !EMAIL_REGEX.test(fieldRefs?.current[item.fieldName]?.value as string)
      ) {
        errors[item.fieldName] = 'Email is invalid';
      }
    }
    if (item.fieldName === FormInputNames.FIRST_NAME || item.fieldName === FormInputNames.SURNAME) {
      if (
        fieldRefs?.current[item.fieldName]?.value?.trim()
        && !NAME_REGEX.test(fieldRefs?.current[item.fieldName]?.value?.trim() as string)
      ) {
        errors[item.fieldName] = 'Name can only have letters and hyphens';
      }
    }

    if (
      item.fieldType === FormInputTypes.INPUT_TEXT
      && (fieldRefs?.current[item.fieldName]?.value?.trim() as string).length > 255
    ) {
      errors[item.fieldName] = 'Max characters allowed 255';
    }

    if (item.fieldType === FormInputTypes.TEXT_AREA) {
      if ((fieldRefs?.current[item.fieldName]?.value?.trim() as string).length > 1000) {
        errors[item.fieldName] = 'Maximum 1000 chars allowed';
      }
    }
  });
  setErrors(errors);
  return Object.keys(errors).length === 0;
};
