import React, { useState } from 'react';
import { ColumnSizes } from 'constants/ColumnSizes';
import { IText, Text } from './Text';
import { TabTypes } from '../constants/TabTypes';
import { Mode } from '../constants/Mode';
import { TabBar } from './TabBar';
import { ITitle, Title } from './Title';
import { Column } from './Column';
import { Elevations } from '../constants/Elevations';
import { ImageBlock } from './ImageBlock';
import styles from './TabBlock.module.scss';

interface ITabContent {
  tabName: string;
  title?: ITitle;
  text?: IText;
  desktopImage?: {
    url: string;
    width: number;
    height: number;
    title: string;
  };
  mobileImage: {
    url: string;
    width: number;
    height: number;
    title: string;
  };
}

export interface ITabBlock {
  tabs?: ITabContent[];
  contentBg?: string;
  elevation?: Elevations | string;
}

export const TabBlock = ({ tabs, contentBg = 'initial', elevation = Elevations.FULL }: ITabBlock) => {
  const [active, setActive] = useState<number>(0);
  if (tabs) {
    const names = Array.from(tabs, (tab: ITabContent) => tab.tabName);
    return (
      <div className={`${styles.root} ${styles[elevation]}`} style={{ background: `${contentBg}` }}>
        <Column xxs={ColumnSizes.FULL} lg={ColumnSizes.HALF}>
          <div className={styles.imageWrapper}>
            <ImageBlock
              elevation={Elevations.NO_ELEVATION}
              desktopImage={tabs[active].desktopImage}
              mobileImage={tabs[active].mobileImage}
            />
          </div>
        </Column>
        <Column xxs={ColumnSizes.FULL} lg={ColumnSizes.HALF} style={{ alignSelf: 'flex-start' }}>
          <div className={styles.tabBarWrapper}>
            <div className={styles.tabBar}>
              <TabBar tabs={names} type={TabTypes.BUTTON} mode={Mode.LIGHT} active={active} setActive={setActive} />
            </div>
          </div>
          <div className={styles.tabContent}>
            <Title {...tabs[active].title} />
            <Text {...tabs[active].text} />
          </div>
        </Column>
      </div>
    );
  }
  return null;
};
