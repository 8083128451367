import React from 'react';
import { ColumnSizes } from 'constants/ColumnSizes';
import { HeadingSizes } from 'constants/HeadingSizes';
import { TextMarginBottom } from 'constants/TextMarginBottom';
import Link from 'next/link';
import { CTAType } from '@/constants/CTAType';
import { ContentDisplayModes } from '@/constants/ContentDisplayModes';
import { WrapOrders } from '@/constants/WrapOrders';
import { Text, IText } from './Text';
import { Title, ITitle } from './Title';
import { EyeBrow, IEyebrow } from './EyeBrow';
import { Column } from './Column';
import { Elevations } from '../constants/Elevations';
import { contact } from '../constants/contact';
import styles from './ContentBlock.module.scss';
import { Icon } from './Icon';
import { ImageBlock } from './ImageBlock';
import { Mode } from './Mode';
import { Button, IButton } from './Button';
import { Row } from './Row';
import { FunnelButton, IFunnelButton } from './FunnelButton';
import { IForm, LeadsForm } from './leadsForms/LeadsForm';

export interface IContentBlock {
  desktopImage?: {
    url: string;
    width: number;
    height: number;
    title: string;
  };
  mobileImage?: {
    url: string;
    width: number;
    height: number;
    title: string;
  };
  eyebrow?: IEyebrow;
  text?: IText;
  title?: ITitle;
  listTitle?: ITitle;
  listText?: IText;
  list?: string[];
  form?: IForm;
  elevation?: Elevations | string;
  displayMode?: ContentDisplayModes | string;
  mode?: Mode | string;
  cta?: IButton;
  ctaType?: CTAType | string;
  ctaFunnel?: IFunnelButton;
  ctaVisible?: boolean;
  listCta?: IButton;
  listCtaType?: CTAType | string;
  listCtaVisible?: boolean;
  mobileContentDirection?: WrapOrders | string;
  desktopContentDirection?: WrapOrders | string;
  contentBg?: string;
}

export const ContentBlock = ({
  ctaVisible = false,
  ctaType,
  cta,
  ctaFunnel,
  listCta,
  listCtaType,
  listCtaVisible,
  eyebrow,
  list,
  text,
  listTitle,
  listText,
  title,
  mode = Mode.LIGHT,
  desktopImage,
  mobileImage,
  form,
  elevation = Elevations.FULL,
  displayMode = ContentDisplayModes.IMAGE_TEXT,
  mobileContentDirection = WrapOrders.ROW,
  desktopContentDirection = WrapOrders.ROW,
  contentBg = 'initial',
}: IContentBlock) => (
  <div className={`${styles.root} ${styles[elevation]}`} style={{ background: `${contentBg}` }}>
    <Row xxsWrap={mobileContentDirection} lgWrap={desktopContentDirection}>
      {(displayMode === ContentDisplayModes.CONTACT_DETAILS_AND_IMAGE
        || displayMode === ContentDisplayModes.IMAGE_TEXT
        || displayMode === ContentDisplayModes.IMAGE_LIST
        || displayMode === ContentDisplayModes.IMAGE_AND_FORM) && (
        <Column xxs={ColumnSizes.FULL} lg={ColumnSizes.HALF}>
          <div className={styles.imageWrapper}>
            <ImageBlock
              elevation={Elevations.NO_ELEVATION}
              desktopImage={desktopImage}
              mobileImage={mobileImage}
              imageFit="contain"
            />
          </div>
        </Column>
      )}
      {displayMode === ContentDisplayModes.CONTACT_DETAILS_AND_IMAGE && (
        <Column xxs={ColumnSizes.FULL} lg={ColumnSizes.HALF}>
          <div className={styles.contactInfo}>
            <Title size={HeadingSizes.SMALL_MEDIUM} marginBottom={TextMarginBottom.TINY} mode={mode}>
              <p>{contact.weekdays}</p>
            </Title>
            <div className={`${styles.listItem} ${styles[mode]}`}>
              <Icon size="small">z</Icon>
              <span>
                <strong>Email</strong>
              </span>
              <span className={styles.info}>
                <Link href={`mailto:${contact.email}`}>{contact.email}</Link>
              </span>
            </div>
            <div className={`${styles.listItem} ${styles[mode]}`}>
              <Icon size="small">w</Icon>
              <span>
                <strong>Call&nbsp;&nbsp;&nbsp;</strong>
              </span>
              <span className={styles.info}>
                <Link href={`tel:${contact.phone}`}>{contact.phone}</Link>
              </span>
            </div>
            <div className={`${styles.listItem} ${styles[mode]}`}>
              <Icon size="small">y</Icon>
              <span>
                <strong>SMS&nbsp;</strong>
              </span>
              <span className={styles.info}>
                <Link href={`tel:${contact.SMS}`}>{contact.SMS}</Link>
              </span>
            </div>
          </div>
        </Column>
      )}

      {(displayMode === ContentDisplayModes.IMAGE_TEXT || displayMode === ContentDisplayModes.TEXT_LIST) && (
        <Column xxs={ColumnSizes.FULL} lg={ColumnSizes.HALF}>
          <div className={styles.contactInfo}>
            {eyebrow && <EyeBrow {...eyebrow} />}
            {title && <Title {...title} />}
            {text && <Text {...text} />}

            {ctaVisible && (
              <>
                {ctaType === CTAType.DEFAULT && <Button {...cta} />}
                {ctaType === CTAType.FUNNEL && !ctaFunnel && <FunnelButton>Get Started</FunnelButton>}
                {ctaType === CTAType.FUNNEL && ctaFunnel && <FunnelButton {...ctaFunnel} />}
              </>
            )}
          </div>
        </Column>
      )}

      {(displayMode === ContentDisplayModes.TEXT_LIST || displayMode === ContentDisplayModes.IMAGE_LIST) && (
        <Column xxs={ColumnSizes.FULL} lg={ColumnSizes.HALF}>
          <div className={styles.contactInfo}>
            <Title {...listTitle} />
            <Text {...listText} />
            <ul className={styles.list}>
              {list?.map((listItem, key) => (
                <li className={styles.listItem} key={key}>
                  {listItem}
                </li>
              ))}
            </ul>
            {listCtaVisible && (
              <>
                {listCtaType === CTAType.DEFAULT && <Button {...listCta} />}
                {listCtaType === CTAType.FUNNEL && <FunnelButton>Get Started</FunnelButton>}
              </>
            )}
          </div>
        </Column>
      )}
      {displayMode === ContentDisplayModes.IMAGE_AND_FORM && form && (
        <Column xxs={ColumnSizes.FULL} lg={ColumnSizes.HALF}>
          {title && <Title {...title} />}
          {text && <Text {...text} />}
          <LeadsForm {...form} />
        </Column>
      )}
    </Row>
  </div>
);
