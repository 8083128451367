import React from 'react';
import DOMPurify from 'isomorphic-dompurify';
import { Mode } from '../constants/Mode';
import styles from './AccordianBody.module.scss';
import { AccordianTextFragment } from '../types/schema';

export interface IAccordianBody {
  body: AccordianTextFragment[];
  args?: any;
  active?: Boolean;
  mode?: string;
}

export const AccordianBody = ({ body, mode = Mode.LIGHT }: IAccordianBody) => {
  const sanitizer = DOMPurify.sanitize;
  return (
    <>
      {body.map((item, key) => (
        <div
          key={key}
          className={`${styles.root} ${styles[mode]} ${styles.body}`}
          dangerouslySetInnerHTML={{ __html: sanitizer(item.markdown) }}
        />
      ))}
    </>
    //
  );
};
