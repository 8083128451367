/* eslint-disable no-unused-vars */
export enum Paddings {
  HUGE = 'huge',
  EXTRALARGE = 'extra-large',
  LARGE = 'large',
  MEDIUM = 'medium',
  SMALL = 'small',
  ZERO = 'zero',
  NONE = '',
}
