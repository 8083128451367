import React from 'react';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { Mode } from '@/constants/Mode';
import Link from 'next/link';
import { imageLoader } from '../utilities/imageLoader';
import styles from './Faq.module.scss';

export interface IFaqImage {
  url: string;
  width: number;
  height: number;
  title: string;
}

export interface IFaq {
  image: IFaqImage;
  text: string;
  mode?: Mode | string;
  url: string;
}

export const Faq = ({
  text = '', image, mode = Mode.LIGHT, url = '/home-loans',
}: IFaq) => {
  const router = useRouter();
  return (
    <Link href={url} className={`${styles.root} ${styles[mode]} ${router.asPath === url ? styles.active : ''}`}>
      <>
        {image && image.url && (
          <div style={{ width: '20px', height: '20px', position: 'relative' }}>
            <Image src={image.url} fill style={{ objectFit: 'contain' }} alt={image.title} loader={imageLoader} />
          </div>
        )}

        <div className={styles.text}>{text}</div>
      </>
    </Link>
  );
};
