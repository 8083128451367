import React from 'react';
import Image from 'next/image';
import styles from './ResponsiveImageBlock.module.scss';
import { imageScaler } from '../../utilities/imageScaler';
import { imageLoader } from '../../utilities/imageLoader';

export const ResponsiveImageBlock = ({
  imageAlt,
  fullWidth,
  imageDesktopURL,
  desktopWidth,
  desktopHeight,
  desktopMaxWidth,
  imageMobileURL,
  mobileWidth,
  mobileHeight,
  mobileMaxWidth,
}: {
  imageAlt: string;
  fullWidth: boolean;
  imageDesktopURL: string;
  imageMobileURL: string;
  desktopWidth: string;
  desktopHeight: string;
  desktopMaxWidth: number;
  mobileWidth: string;
  mobileHeight: string;
  mobileMaxWidth: number;
}) => {
  const scaledDownHeightDesktop = imageScaler(desktopMaxWidth, parseFloat(desktopWidth), parseFloat(desktopHeight));
  const scaledDownHeightMobile = imageScaler(mobileMaxWidth, parseFloat(mobileWidth), parseFloat(mobileHeight));

  return (
    <>
      <div className={`${styles.image} ${styles.desktop} ${fullWidth ? styles['full-width'] : ''} `}>
        <Image
          alt={imageAlt}
          src={`${imageDesktopURL}?fm=webp&w=${desktopMaxWidth}&h=${scaledDownHeightDesktop}&q=100`}
          width={desktopMaxWidth}
          height={scaledDownHeightDesktop}
          loader={imageLoader}
          unoptimized
        />
      </div>
      <div className={`${styles.image} ${styles.mobile} ${fullWidth ? styles['full-width'] : ''}`}>
        <Image
          alt={imageAlt}
          src={`${imageMobileURL}?fm=webp&w=${mobileMaxWidth}&h=${scaledDownHeightMobile}&q=100`}
          width={mobileMaxWidth}
          height={scaledDownHeightMobile}
          loader={imageLoader}
          unoptimized
        />
      </div>
    </>
  );
};
