import React from 'react';
import { Mode } from '../constants/Mode';
import Expand from './Expand';
import styles from './Accordian.module.scss';
import { AccordianTextFragment } from '../types/schema';

export interface IAccordian {
  title: string;
  bodyCollection: AccordianTextFragment[];
  args?: any;
  mode?: Mode | string;
}

export const Accordian = ({ title, bodyCollection, mode = Mode.LIGHT }: IAccordian) => (
  <div className={`${styles.root} ${styles[mode]}`}>
    <Expand title={title} body={bodyCollection} mode={mode} />
  </div>
);
