/* eslint-disable react/no-unstable-nested-components */
import React from 'react';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { NodeData, BLOCKS } from '@contentful/rich-text-types';
import { IDictionaryRate } from '@/queries/fetchRates';
import {
  PageBlockFragment,
  FaqsFragment,
  AccordiansListFragment,
  MediaBlockFragment,
  CardVariantListFragment,
  RenderComponentFragment,
  RichTextBlockFragment,
  RateTableFragment,
  VideoContainerFragment,
  ArticleBlockFragment,
  ProductRateTableFragment,
  FormFragment,
  UpdatedHomePageBannerFragment,
} from '../types/schema';
import { PageBlock } from './PageBlock';
import { Container } from './Container';
import { FaqSelector } from './FaqSelector';
import { AccordianList } from './AccordianList';
import { MediaBlock } from './MediaBlock';
import { CardVariantList } from './CardVariantList';
import { ResponsiveImageBlock } from './learn/ResponsiveImageBlock';
import { Text } from './Text';
import { Title } from './Title';
import { RateTable } from './RateTable';
import { RenderComponent } from './RenderComponent';
import { Video } from './Video';
import { ArticleBlock } from './ArticleBlock';
import { ProductRateTable } from './ProductRateTable';
import { LeadsForm } from './leadsForms/LeadsForm';
import { HomePageBanner } from './HomePageBanner/HomePageBanner';

export interface IRenderSwitch {
  content:
  | FaqsFragment
  | AccordiansListFragment
  | MediaBlockFragment
  | CardVariantListFragment
  | RenderComponentFragment
  | RichTextBlockFragment
  | RateTableFragment
  | VideoContainerFragment
  | ArticleBlockFragment
  | ProductRateTableFragment
  | UpdatedHomePageBannerFragment
  | FormFragment;
}

interface IPageAndRates extends PageBlockFragment {
  rates: IDictionaryRate;
}

const RenderSwitch = ({ content }: IRenderSwitch, rates: IDictionaryRate) => {
  let options;
  if (content.__typename === 'RichTextBlock') {
    options = {
      renderNode: {
        [BLOCKS.EMBEDDED_ENTRY]: (node: NodeData) => {
          const entry: any | undefined | null = content.content.links.entries.block.find(
            (element: any) => node?.data.target.sys.id === element.sys.id,
          );
          return entry ? (
            <ResponsiveImageBlock
              imageAlt={entry?.imageAlt}
              fullWidth={entry?.fullWidth}
              imageDesktopURL={entry?.imageDesktop.url}
              desktopWidth={entry?.imageDesktop.width}
              desktopHeight={entry?.imageDesktop.height}
              desktopMaxWidth={992}
              imageMobileURL={entry?.imageMobile.url}
              mobileWidth={entry?.imageMobile.width}
              mobileHeight={entry?.imageMobile.height}
              mobileMaxWidth={480}
            />
          ) : null;
        },
        [BLOCKS.PARAGRAPH]: (_node: NodeData, children: React.ReactNode) => (
          <Text>
            <p>{children}</p>
          </Text>
        ),
        [BLOCKS.HEADING_1]: (_node: NodeData, children: React.ReactNode) => (
          <Title size="xl">
            <h1>{children}</h1>
          </Title>
        ),
        [BLOCKS.HEADING_2]: (_node: NodeData, children: React.ReactNode) => (
          <Title size="l">
            <h2>{children}</h2>
          </Title>
        ),
        [BLOCKS.HEADING_3]: (_node: NodeData, children: React.ReactNode) => (
          <Title size="m">
            <h3>{children}</h3>
          </Title>
        ),
        [BLOCKS.HEADING_4]: (_node: NodeData, children: React.ReactNode) => (
          <Title size="sm">
            <h4>{children}</h4>
          </Title>
        ),
        [BLOCKS.HEADING_5]: (_node: NodeData, children: React.ReactNode) => (
          <Title size="sm">
            <h5>{children}</h5>
          </Title>
        ),
        [BLOCKS.HEADING_6]: (_node: NodeData, children: React.ReactNode) => (
          <Title size="s">
            <h6>{children}</h6>
          </Title>
        ),
        [BLOCKS.TABLE]: (_node: NodeData, children: React.ReactNode) => (
          <div className="table">
            <table>
              <tbody>{children}</tbody>
            </table>
          </div>
        ),
      },
    };
  }

  if (content?.__typename) {
    switch (content.__typename) {
      case 'Faqs':
        return <FaqSelector faqs={content.faqsCollection.items} />;
      case 'AccordiansList':
        return (
          <AccordianList
            title={content.title}
            eyebrow={content.eyebrow}
            about={content.about}
            accordians={content.accordiansCollection.items}
            mode={content.mode}
            id={content.id}
          />
        );
      case 'MediaBlock':
        return (
          <MediaBlock
            {...content}
            form={{ ...content.form, textInputs: content?.form?.textInputsCollection?.items }}
            content={{
              ...content.content,
              form: { ...content.content?.form, textInputs: content?.content?.form?.textInputsCollection?.items },
            }}
          />
        );
      case 'RenderComponent':
        return <RenderComponent {...content} rates={rates} />;
      case 'RateTable':
        return (
          <RateTable
            rates={rates}
            mode={content.mode}
            repaymentType={content.repaymentType}
            propertyPurpose={content.propertyPurpose}
            classic={content.classic}
            title={content.title}
            subtitle={content.subtitle}
            id={content.id}
          />
        );
      case 'CardVariantList':
        return (
          <CardVariantList
            text={content.text}
            title={content.titleText}
            cardVariants={content.cardVariantsCollection.items}
          />
        );
      case 'UpdatedHomePageBanner':
        return <HomePageBanner />;
      case 'RichTextBlock':
        return <div>{documentToReactComponents(content.content.json, options)}</div>;
      case 'VideoContainer':
        return <Video {...content} eyeBrow={content.eyebrow} videoModal={content.videoModalCollection.items} />;
      case 'ArticleBlock':
        return <ArticleBlock {...content} text={content.textCollection.items} ctas={content.ctasCollection.items} />;
      case 'ProductRateTable':
        return <ProductRateTable {...content} rates={rates} />;
      case 'Form':
        return (
          <LeadsForm
            {...content}
            eyebrow={content.eyebrow}
            textInputs={content.textInputsCollection.items}
            formTitle={content.formTitle}
          />
        );
      default:
        return 'default';
    }
  } else {
    return null;
  }
};

/* Content types that don't require the page block wrapper */
const standaloneContentTypes = ['ArticleBlock', 'UpdatedHomePageBanner'];

export const BlockRenderer = ({
  bg,
  slantedbg,
  horizontalPadding,
  desktopTopPadding,
  desktopBottomPadding,
  mobileTopPadding,
  mobileBottomPadding,
  backgroundSlant,
  container,
  content,
  rates,
}: IPageAndRates) => {
  if (content && standaloneContentTypes.includes(content.__typename)) {
    return <div>{RenderSwitch({ content }, rates)}</div>;
  }

  return (
    <PageBlock
      bg={bg}
      slantedbg={slantedbg}
      desktopPaddingTop={desktopTopPadding}
      desktopPaddingBottom={desktopBottomPadding}
      mobilePaddingTop={mobileTopPadding}
      mobilePaddingBottom={mobileBottomPadding}
      slanted={backgroundSlant}
    >
      <Container size={container} horizontalPadding={horizontalPadding}>
        {content && content.__typename && RenderSwitch({ content }, rates)}
      </Container>
    </PageBlock>
  );
};
