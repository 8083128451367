export const HomePageBannerBackgroundSlant = ({ percentage }: { percentage: number }) => (
  <div
    style={{
      backgroundColor: 'rgb(249, 246, 250)',
      clipPath: `polygon(0% ${100 + percentage}%, 100% ${Math.abs(percentage)}%, 100% 100%, 0% 100%)`,
      height: '25vh',
      left: '-1rem',
      position: 'absolute',
      right: '-1rem',
      bottom: '0',
      zIndex: 0,
    }}
  />
);
