import React from 'react';
import { Buttons } from 'constants/Buttons';
import { ButtonTags } from '@/constants/ButtonTags';
import { responsiveSrcGenerator } from 'utilities/responsiveSrcGenerator';
import styles from './CardTilesBlock.module.scss';
import { ITitle, Title } from './Title';
import { Text, IText } from './Text';
import { Button } from './Button';
import { srcSetGenerator } from '../utilities/srcSetGenerator';

export interface ITile {
  title: ITitle;
  text: IText;
  image: {
    url: string;
    width: number;
    height: number;
    title: string;
  };
  href: string;
}

export interface ICardTilesBlock {
  cards?: ITile[];
}

export const CardTilesBlock = ({ cards }: ICardTilesBlock) => (
  <div className={`${styles.root}`}>
    {cards
      && cards.map((tile, i) => {
        const TileTag = tile.href ? 'a' : 'div';
        const TileTagProps = tile.href ? { href: tile.href } : {};
        return (
          <TileTag className={styles.block} key={i} {...TileTagProps}>
            <div className={styles.image}>
              {tile && tile.image && tile.image.url && (
                <img
                  src={responsiveSrcGenerator(tile.image.url, tile.image.width, tile.image.height)}
                  srcSet={srcSetGenerator(tile.image.url, tile.image.width, tile.image.height)}
                  width={Math.round(tile.image.width / 2)}
                  height={Math.round(tile.image.height / 2)}
                  alt={tile.image.title}
                  loading="lazy"
                />
              )}
            </div>
            <div className={styles.text}>
              <Title {...tile.title} />
              <Text {...tile.text} />
            </div>
            {tile.href && (
              <div className={styles.link}>
                <Button icon="r" type={Buttons.OUTLINE} ButtonTag={ButtonTags.SPAN} args={{ href: tile.href }} />
              </div>
            )}
          </TileTag>
        );
      })}
  </div>
);
