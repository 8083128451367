import { LeadsRequest } from 'types/leadsRequest';
import { SANITISE_REGEX } from '../constants/FormInputsRegex';
import { setData } from '../utilities/leadsObjAssign';

export const sanitiseData = (data: LeadsRequest) => {
  Object.keys(data).forEach((key) => {
    if (typeof data[key as keyof LeadsRequest] === 'string') {
      const value: string = (data[key as keyof LeadsRequest] as string).replace(SANITISE_REGEX, '');
      setData(key as keyof LeadsRequest, value, data);
    }
  });
};
