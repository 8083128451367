// This form is tightly coupled with leads api atm.
// Hence treat it like a leads gen form

import React, { useEffect, useRef, useState } from 'react';
import { formInputValidation } from 'logic/formFieldsValidation';
import { leadsMapperService } from 'logic/leadsMapperService';
import { LeadSource } from '@/constants/LeadSource';
import { sanitiseData } from 'logic/sanitiseLeads';
import { Paddings } from '@/constants/Paddings';
import { Elevations } from '@/constants/Elevations';
import { FormTextInputs, IFormTextInputs } from './FormTextInputs';
import { ITitle, Title } from '../Title';
import { EyeBrow, IEyebrow } from '../EyeBrow';
import { Mode } from '../Mode';
import styles from './Form.module.scss';
import { IText, Text } from '../Text';

export type RefType = {
  [key: string]: HTMLInputElement | HTMLTextAreaElement | null;
};
export interface IForm {
  eyebrow?: IEyebrow;
  formTitle?: ITitle;
  textInputs: IFormTextInputs[];
  mode?: Mode | string;
  desktopPaddingTop?: Paddings | string;
  desktopPaddingBottom?: Paddings | string;
  mobilePaddingTop?: Paddings | string;
  mobilePaddingBottom?: Paddings | string;
  elevation?: Elevations | string;
  leadSource: LeadSource | string;
  successPageUrl: string;
  failurePageUrl: string;
  disclaimer?: IText;
  disclaimerOnSide: boolean;
  bg: string;
}

export const LeadsForm = ({
  eyebrow,
  formTitle,
  textInputs,
  mode = Mode.LIGHT,
  desktopPaddingTop = Paddings.LARGE,
  desktopPaddingBottom = Paddings.LARGE,
  mobilePaddingBottom = Paddings.LARGE,
  mobilePaddingTop = Paddings.LARGE,
  elevation = Elevations.NO_ELEVATION,
  leadSource,
  successPageUrl,
  failurePageUrl,
  disclaimer,
  disclaimerOnSide = false,
  bg,
}: IForm) => {
  const fieldRefs = useRef<RefType>(
    textInputs.reduce((acc, { fieldName }) => {
      acc[fieldName] = null;
      return acc;
    }, {} as RefType),
  );
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [success, setSuccess] = useState(false);
  const [fail, setFail] = useState(false);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setErrors({});
    const validate = formInputValidation({ textInputs, fieldRefs, setErrors });
    if (validate) {
      const data = leadsMapperService({ fieldRefs });
      sanitiseData(data); // sanitise form inputs
      data.leadSource = leadSource;
      data.leadSourceUrl = window.location.href;
      try {
        await fetch(`${process.env.NEXT_PUBLIC_LEADS_SERVICE_URL}/create`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
        });
        setSuccess(true);
      } catch (e) {
        setFail(true);
      }
    }
  };

  useEffect(() => {
    if (success) {
      window.location.href = `/${successPageUrl}`;
    }
    if (fail) {
      window.location.href = `/${failurePageUrl}`;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [success, fail]);
  return (
    <div
      className={`
      ${styles.root}
    ${styles[`desktop-${desktopPaddingTop}-top`]}
    ${styles[`desktop-${desktopPaddingBottom}-bottom`]}
    ${styles[`mobile-${mobilePaddingTop}-top`]}
    ${styles[`mobile-${mobilePaddingBottom}-bottom`]}
    ${styles[`${mode}-background`]}
    ${styles[`${elevation}`]}
    `}
      style={{
        backgroundColor: bg || '',
      }}
    >
      {eyebrow && <EyeBrow {...eyebrow} />}
      {formTitle && <Title {...formTitle} />}
      <form onSubmit={handleSubmit}>
        {textInputs.map((item, i) => (
          <FormTextInputs
            {...item}
            key={i}
            // eslint-disable-next-line no-return-assign
            inputRef={(el) => (fieldRefs.current[item.fieldName] = el as HTMLInputElement | HTMLTextAreaElement)}
            errorMsg={errors[item.fieldName]}
            mode={mode}
          />
        ))}
        <div className={`${styles['disclamer-and-btn']}`}>
          {disclaimer && (
            <div className={disclaimerOnSide ? `${styles['disclaimer-side']}` : ''}>
              <Text {...disclaimer} mode={mode} />
            </div>
          )}
          <input type="submit" value="Submit" className={`${styles.solid} ${styles[mode]}`} />
        </div>
      </form>
    </div>
  );
};
