import React, { useState } from 'react';
import { ContainerSizes } from 'constants/ContainerSizes';
import { JustifyContents } from '@/constants/JustifyContents';
import { Mode } from '@/constants/Mode';
import { TextAlignments } from '@/constants/TextAlignments';
import { Paddings } from '@/constants/Paddings';
import { AccentedBackgrounds } from '@/constants/AccentedBackgrounds';
import { TextMarginBottom } from '@/constants/TextMarginBottom';
import styles from './ArticleBlock.module.scss';
import stylesText from './TextBlock.module.scss';
import { PageBlock } from './PageBlock';
import { Background } from '../constants/Background';
import { Container } from './Container';
import { Column } from './Column';
import { Row } from './Row';
import { ColumnSizes } from '../constants/ColumnSizes';
import { Button, IButton } from './Button';
import { ITextBlock, TextBlock } from './TextBlock';
import { Text } from './Text';
import { NewArticles } from './NewArticles';
import { IGenericBannerImage } from './GenericBanner';

export interface IArticleBlock {
  durationText?: string[];
  duration?: string[];
  date?: string[];
  ctas?: IButton[];
  text: ITextBlock[];
  desktopAlignment?: TextAlignments;
  mobileAlignment?: TextAlignments;
  imgOne: IGenericBannerImage;
  imgTwo: IGenericBannerImage;
  imgThree: IGenericBannerImage;
}

export const ArticleBlock = ({
  date = [],
  duration = [],
  durationText = [],
  ctas,
  text,
  desktopAlignment = TextAlignments.LEFT,
  mobileAlignment = TextAlignments.CENTER,
  imgOne = { url: '', title: '' },
  imgTwo = { url: '', title: '' },
  imgThree = { url: '', title: '' },
}: IArticleBlock) => {
  const [active, setActive] = useState<number>(0);

  return (
    <PageBlock
      background={Background.CLIPPED_RESPONSIVE}
      accentedBackground={AccentedBackgrounds.DOUBLE_CLIPPED_RESPONSIVE}
      desktopPaddingTop={Paddings.HUGE}
      mobilePaddingBottom={Paddings.HUGE}
      mobilePaddingTop={Paddings.HUGE}
    >
      <Container size={ContainerSizes.LARGE}>
        <Row lgJust={JustifyContents.CENTER}>
          <Column xxs={ColumnSizes.FULL} lg={ColumnSizes.HALF}>
            <div className={`${styles.text}`}>
              {text.map((item, key) => (
                <React.Fragment key={key}>
                  {active === key && <TextBlock key={key} {...item} />}
                  {active === key && (
                  <div className={`${styles['extra-margin']}`}>
                    <Text
                      desktopAlignment={TextAlignments.LEFT}
                      mobileAlignment={TextAlignments.CENTER}
                      mode={Mode.DARK}
                      marginBottom={TextMarginBottom.SMALL}
                      markdown={`${duration[key]} ${durationText[key]} | ${date[key]}`}
                    />
                  </div>
                  )}
                </React.Fragment>
              ))}
              <div
                className={`${stylesText[`desktop-${desktopAlignment}`]} ${
                  stylesText[`mobile-${mobileAlignment}`]
                }`}
              >
                {ctas?.map((item, key) => (
                  <React.Fragment key={key}>
                    {active === key && <Button {...item} />}
                  </React.Fragment>
                ))}
              </div>
            </div>
          </Column>
          <Column xxs={ColumnSizes.FULL} lg={ColumnSizes.HALF}>
            <NewArticles imgOne={imgOne.url} imgTwo={imgTwo.url} imgThree={imgThree.url} handleClickEnd={setActive} />
          </Column>
        </Row>
      </Container>
    </PageBlock>
  );
};
