/* eslint-disable max-len */
import React, { useState } from 'react';
import { TabTypes } from '@/constants/TabTypes';
import {
  FixedYears, IDictionaryRate, IRate, LVRTier,
} from '@/queries/fetchRates';
import { TabBar } from './TabBar';
import styles from './RateTable.module.scss';
import { Mode } from './Mode';
import { ITitle, Title } from './Title';
import { convertRatesToStrings } from '../utilities/convertRatesToStrings';

/* Taken from CMS */
type Tiers = 'fixed' | 'direct-basics' | 'direct-extras';
interface IRateTable {
  rates: IDictionaryRate;
  tier: string;
  title?: ITitle;
  subtitle?: ITitle;
}

interface IlvrNames {
  [key: string]: 'Liberate' | 'Evaporate' | 'Celebrate' | 'Obliterate';
}

interface IPropertyPurpose {
  [key: number]: 'OWNER_OCCUPIED' | 'INVESTMENT';
}
interface IRepaymentType {
  [key: number]: 'PRINCIPAL_AND_INTEREST' | 'INTEREST_ONLY';
}

export const ProductRateTable = ({
  rates, tier, title, subtitle,
}: IRateTable) => {
  const typedTier = tier as Tiers;
  const [propertyPurpose, setPropertyPurpose] = useState<number>(0);
  const [repaymentType, setRepaymentType] = useState<number>(0);
  const [fixedYear, setFixedYear] = useState<number>(0);

  const propertyPurposeIndex: IPropertyPurpose = {
    0: 'OWNER_OCCUPIED',
    1: 'INVESTMENT',
  };

  const repaymentTypeIndex: IRepaymentType = {
    0: 'PRINCIPAL_AND_INTEREST',
    1: 'INTEREST_ONLY',
  };

  const lvrNames: IlvrNames = {
    '70-80': 'Liberate',
    '60-70': 'Evaporate',
    '50-60': 'Celebrate',
    '0-60': 'Celebrate',
    '0-50': 'Obliterate',
  };

  return (
    <>
      {title && <Title {...title} />}
      {subtitle && <Title {...subtitle} />}
      <div className={styles.root}>
        <div className={styles.controlTabBar}>
          <TabBar
            tabs={['Owner Occupied', 'Investor']}
            type={TabTypes.BUTTON}
            setActive={setPropertyPurpose}
            active={propertyPurpose}
            stretch
            mode={Mode.LIGHT}
          />
        </div>
        <div className={styles.controlTabBar}>
          <TabBar
            tabs={['Principal & Interest', 'Interest Only']}
            type={TabTypes.BUTTON}
            setActive={setRepaymentType}
            active={repaymentType}
            stretch
            mode={Mode.LIGHT}
          />
        </div>
        {typedTier === 'fixed' && (
          <div className={styles.controlTabBar}>
            <TabBar
              tabs={['1 Year', '2 Years', '3 Years']}
              type={TabTypes.BUTTON}
              setActive={setFixedYear}
              active={fixedYear}
              stretch
              mode={Mode.LIGHT}
            />
          </div>
        )}

        <div className="table">
          <table style={{ width: '100%' }}>
            <tbody>
              <tr>
                <th>LVR Tier</th>
                <th>Rate</th>
                <th>Comparison Rate</th>
              </tr>
              {(typedTier === 'direct-basics' || typedTier === 'direct-extras') && (
                <>
                  {Object.keys(
                    rates[typedTier].VARIABLE_RATE[repaymentTypeIndex[repaymentType]][
                      propertyPurposeIndex[propertyPurpose]
                    ],
                  )
                    .map((lvrTier: string, index: number) => {
                      const lvrObj = rates[typedTier].VARIABLE_RATE[repaymentTypeIndex[repaymentType]][
                        propertyPurposeIndex[propertyPurpose]
                      ][lvrTier as LVRTier];

                      const { rate, comparisonRate } = convertRatesToStrings(lvrObj as IRate);

                      return (
                        <tr key={index}>
                          <td>
                            <p style={{ marginBottom: lvrNames[lvrTier] ? '8px' : '0' }}>
                              {lvrTier}
                              %
                            </p>
                            {lvrNames[lvrTier] && <p style={{ color: 'black' }}>{lvrNames[lvrTier]}</p>}
                          </td>
                          <td>
                            {(parseFloat(rate) * 100).toFixed(2)}
                            %
                          </td>
                          {lvrObj?.comparisonRate && (
                          <td>
                            {(parseFloat(comparisonRate) * 100).toFixed(2)}
                            %
                          </td>
                          )}
                        </tr>
                      );
                    })}
                </>
              )}
              {typedTier === 'fixed' && (
                <>
                  {Object.keys(
                    rates['direct-basics']?.FIXED_RATE[`${fixedYear + 1}` as FixedYears][
                      repaymentTypeIndex[repaymentType]
                    ][propertyPurposeIndex[propertyPurpose]],
                  )
                    .map((lvrTier: string, index: number) => {
                      const lvrObj = rates['direct-basics'].FIXED_RATE[`${fixedYear + 1}` as FixedYears][
                        repaymentTypeIndex[repaymentType]
                      ][propertyPurposeIndex[propertyPurpose]][lvrTier as LVRTier];

                      const { rate, comparisonRate } = convertRatesToStrings(lvrObj as IRate);

                      return (
                        <tr key={index}>
                          <td>
                            <p style={{ marginBottom: lvrNames[lvrTier] ? '8px' : '0' }}>
                              {lvrTier}
                              %
                            </p>
                            {lvrNames[lvrTier] && <p style={{ color: 'black' }}>{lvrNames[lvrTier]}</p>}
                          </td>
                          <td>
                            {(parseFloat(rate) * 100).toFixed(2)}
                            %
                          </td>
                          <td>
                            {(parseFloat(comparisonRate) * 100).toFixed(2)}
                            %
                          </td>
                        </tr>
                      );
                    })}
                </>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};
