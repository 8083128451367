import React, { useState } from 'react';
import { ContainerSizes } from '@/constants/ContainerSizes';
import { TextAlignments } from '@/constants/TextAlignments';
import { EyeBrow, IEyebrow } from './EyeBrow';
import { IText, Text } from './Text';
import { ITitle, Title } from './Title';
import { Container } from './Container';
import styles from './Video.module.scss';
import { VideoModal, IVideoModal } from './VideoModal';
import { IButton } from './Button';

export interface IVideo {
  eyeBrow?: IEyebrow;
  headline?: ITitle;
  playBtnBg?: string;
  playBtnColor?: string;
  playText?: IText;
  videoModal: IVideoModal[];
  relatedVideoText: ITitle;
  cta?: IButton;
  backText?: IText;
}

export const Video = ({
  eyeBrow,
  headline,
  playBtnBg = 'var(--white)',
  playBtnColor,
  playText,
  videoModal,
  relatedVideoText,
  cta,
  backText,
}: IVideo) => {
  const [active, setActive] = useState<boolean>(false);
  const [activeVid, setActiveVid] = useState<number>(0);
  // eslint-disable-next-line max-len
  const [activeRelatedVid, setActiveRelatedVid] = useState<number>(1); // this always starts from 1 as it's the next video

  return (
    <>
      <Container size={ContainerSizes.LARGE}>
        <EyeBrow {...eyeBrow} />
        <Title {...headline} desktopAlignment={TextAlignments.CENTER} />
        <div
          className={styles.root}
          style={{
            background: `linear-gradient(183.9deg, rgba(0, 0, 0, 0) -2.48%, #000000 96.81%), url(${videoModal[0].backgroundImage?.url})`,
          }}
          onClick={() => {
            setActive(true);
          }}
        >
          <div className={styles.story}>
            <span
              className={styles.icon}
              style={{ backgroundColor: `${playBtnBg}`, color: `${playBtnColor}` }}
              onClick={() => {
                setActive(true);
              }}
            />
            <div className={styles.play}>
              <Text {...playText} />
            </div>
            <Title {...videoModal[0].introTitle} />
            <Text {...videoModal[0].introText} />
          </div>
        </div>
      </Container>
      {active
        && videoModal.map((item, index) => (
          <>
            {activeVid === index && (
            <VideoModal
              {...item}
              key={index}
              cta={cta}
              backText={backText}
              handleClick={setActive}
              activeRelatedVid={activeRelatedVid}
              handleActiveVid={setActiveVid}
              activeVid={index}
              backBtnBg={playBtnBg}
              backBtnColor={playBtnColor}
              handleActiveRelatedVid={setActiveRelatedVid}
              videoListLength={videoModal.length}
              relatedVideoText={relatedVideoText}
              backgroundImage={
                    index + 1 >= videoModal.length
                      ? videoModal[0].backgroundImage
                      : videoModal[index + 1].backgroundImage
                  }
              anchorId={item.anchorId}
            />
            )}
          </>
        ))}
    </>
  );
};
