import React from 'react';
import styles from './AccordianList.module.scss';
import { Accordian } from './Accordian';
import { Mode } from '../constants/Mode';
import { EyeBrow, IEyebrow } from './EyeBrow';
import { Text, IText } from './Text';
import { Title, ITitle } from './Title';

import { SingleAccordianFragment } from '../types/schema';

export interface IAccordianList {
  accordians: SingleAccordianFragment[];
  eyebrow?: IEyebrow;
  title?: ITitle;
  about?: IText;
  mode?: Mode | string;
  id?: string
}

export const AccordianList = ({
  title, eyebrow, about, accordians, mode = Mode.LIGHT, id,
}: IAccordianList) => {
  // eslint-disable-next-line object-shorthand
  const elementProps = id ? { id: id } : {};
  return (
    <>
      <EyeBrow {...eyebrow} />

      <Title {...title} />

      <Text {...about} />
      <div {...elementProps} className={`${styles.root} ${styles[mode]}`}>
        {accordians.map((accordian, i) => (
          <Accordian
            key={i + new Date().toString()}
            title={accordian.title}
            bodyCollection={accordian.bodyCollection.items}
            mode={mode}
          />
        ))}
      </div>
    </>
  );
};
